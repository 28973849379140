%tagliste {
  @extend %clearfix;

  li {
    float: left;
    margin-right: 2px;
    margin-bottom: 2px;

    a {
      background: $border-color;
      border-radius: 4px;
      padding: 5px 7px;
      display: inline-block;
    }
  }
}
