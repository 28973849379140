.paragraphs-item-kontaktdaten {
  .field {
    margin: .5em 0 .5em 25px;

    &:first-child {
      margin-top: 0;
    }

    &:last-child {
      margin-bottom: 0;
    }

    &:before {
      @include fontawesome($fa-var-phone);
      width: 20px;
      float: left;
      margin-left: -25px;
      text-align: center;
      position: relative;
      top: 5px;
    }

    &.field-name-field-mobil:before {
      content: $fa-var-mobile;
    }

    &.field-name-field-fax:before {
      content: $fa-var-print;
      top: 1px;
    }

    &.field-name-field-e-mail:before {
      content: $fa-var-envelope-o;
    }

    &.field-name-field-adresse:before {
      content: $fa-var-map-marker;
    }
  }
}
