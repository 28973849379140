.paragraphs-item-bildergalerie {
  .field-name-field-bilder {
    @extend %clearfix;

    a.colorbox {
      display: block;
      @include gh-image-hover();

      img {
        @include resp-img();
        box-shadow: 0 0 10px 0 $shadow-color;
      }
    }
  }

  @include el-query(null, span-breite(3)) {
    .field-name-field-bilder {
      margin-top: 0 - $dist / 2;

      a.colorbox {
        margin-top: $dist / 2;
      }
    }
  }

  @include el-query(span-breite(3), span-breite(6)) {
    .field-name-field-bilder {
      a.colorbox {
        @include gh-gallery(2);
      }
    }
  }

  @include el-query(span-breite(6), span-breite(7)) {
    .field-name-field-bilder {
      a.colorbox {
        @include gh-gallery(3);
      }
    }
  }

  @include el-query(span-breite(7), null) {
    .field-name-field-bilder {
      a.colorbox {
        @include gh-gallery(4);
      }
    }
  }
}
