////
/// @author Max Bachhuber
/// @group grasenhiller
////

/// Centers a div in a "position relative" or "position absolute" container
///
/// @param {String} $option - Where to center the item. Can be one of "vertical", "v", "horizontal", "h", "both", "b";
/// @require {mixin} transform
///
/// @example scss - Basic usage
///   .outer {
///     position: relative;
///
///     .inner {
///       @include gh-center("vertical");
///     }
///   }
@mixin gh-center($option) {
  position: absolute;

  @if $option == "vertical" or $option == "v" or $option == "both" or $option == "b" {
    top: 50%;
  }

  @if $option == "horizontal" or $option == "h" or $option == "both" or $option == "b" {
    left: 50%;
  }

  @if $option == "vertical" or $option == "v" {
    transform: translateY(-50%);
  }

  @if $option == "horizontal" or $option == "h" {
    transform: translateX(-50%);
  }

  @if $option == "both" or $option == "b" {
    transform: translate(-50%, -50%);
  }
}
