.node-kompetenz.view-mode-full {
  & > .field-name-field-einzeiliger-text {
    h1 {
      font-size: $fs-bigger;
      text-align: center;
    }
  }

  & > .field-name-body {
    margin:0 auto;
    max-width: 600px;
    text-align: center;
  }

  & > .field-name-field-blogbeitraege {
    margin-top: $dist;

    .label-above {
      @extend h2;
      @include icon-before($fa-var-bullhorn);
      padding-bottom: .5em;
      margin-bottom: $dist;
      border-bottom: solid 2px $highlight-color;

      &:before {
        top: 4px;
      }
    }
  }

  & > .field-name-custom-node-kompetenz-jump-list {
    margin-top: $dist * 2;

    @include breakpoint(null, $bp-m) {
      display: none;
    }
  }

  & > .field-name-field-eintraege {
    & > .node {
      margin-top: $dist * 2;
      border-top: solid 1px $border-color;
      padding-top: $dist * 2;
    }
  }

  & > .field-name-custom-kompetenz-videos {
    margin-top: $dist * 2;

    .block > h2 {
      @include icon-before($fa-var-play);
      padding-bottom: .5em;
      margin-bottom: $dist;
      border-bottom: solid 2px $highlight-color;

      &:before {
        top: 4px;
      }
    }
  }

  & > .field-name-field-zahlen-fakten-eintraege {
    margin-top: $dist * 2;
    border-top: solid 1px $border-color;
    padding-top: $dist * 2;
    @extend .paragraphs-item-zahlen-fakten;
  }

  & > .group-three-blocks {
    @extend %clearfix;
    margin-top: $dist * 2;
    border-top: solid 1px $border-color;
    padding-top: $dist * 2;

    & > .field {
      $grid-config: (
        (null, $bp-s, 1),
        ($bp-s, $bp-l, 2),
        ($bp-l, null, 3)
      );

      @include resp-gal($grid-config);
      background-color: $box-color;
      padding: $dist / 2;

      h2 {
        text-align: center;
        font-size: $fs-big;
      }

      table {
        border: none;
        background: none;

        tr {
          background: none;
          border: none;

          td {
            background: none;
            border: none;
            padding: 0;
          }
        }
      }

      .bean-freitext-block {
        p {
          a {
            display: inline-block;
            @include icon-before($fa-var-phone);

            &:before {
              color: $font-color-light;
            }

            &:nth-of-type(2):before {
              content: $fa-var-print;
            }

            &:nth-of-type(3):before {
              content: $fa-var-paper-plane-o;
            }
          }
        }
      }
    }
  }
}

#block-ds-extras-kompetenz-fab {

}

#block-views-partner-block-1 {
  margin-top: $dist * 2;

  & > h2 {
    @include icon-before($fa-var-group);
    padding-bottom: .5em;
    margin-bottom: $dist;
    border-bottom: solid 2px $highlight-color;;

    &:before {
      top: 4px;
    }
  }
}

.node-kompetenz.view-mode-teaser {
  @extend .node-referenz.view-mode-teaser;
}
