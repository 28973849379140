@import "../../../bower_components/font-awesome/scss/variables";

////
/// @author Max Bachhuber
/// @group grasenhiller
////

/// Displays a fontawesome icon
///
/// Should be applied to a `:before` or `:after` of an element
///
/// @param {String} $icon - The unicode id of a fontawesome icon. You could use the `$fa` variables.
///
/// @example scss - Basic usage
///   a.telefon:before {
///     @include fontawesome($fa-telephone);
///   }

@mixin fontawesome($icon) {
  content: $icon;
  font-family: FontAwesome;
  display: inline-block;
  font-style: normal;
  font-weight: normal;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 1em;
  text-align: center;
}
