////
/// @author Max Bachhuber
/// @group grasenhiller
////

/// Adds an icon before an element and aligns the text at the icon
///
/// @param {String} $icon [$fa-home] - The unicode id of a fontawesome icon. You could use the `$fa` variables.
/// @param {Pixel} $width [20px] - The width of the icon
/// @param {Pixel} $margin [5px] - The margin from the icon to the text
/// @require {mixin} fontawesome
///
/// @example scss - Basic usage
///   .field-name-field-adresse {
///     @include gh-icon-with-padding($fa-home);
///   }

@mixin gh-icon-with-padding($icon: $fa-home, $width: 1em, $margin: 0.5em) {
  padding-left: $width + $margin;

  &:before {
    @include fontawesome($icon);
    width: $width;
    margin-left: 0 - $width - $margin;
    float: left;
    text-align: center;
    position: relative;
    top: 2px;
  }
}

/// @alias gh-icon-with-padding

@mixin icon-before($args...) {
  @include gh-icon-with-padding($args...);
}
