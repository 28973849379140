////
/// @author Max Bachhuber
/// @group grasenhiller
////

/// Sets an images width and height so that it shrinks fluid.

@mixin gh-responsive-image() {
  width: 100%;
  height: auto;
}


/// @alias gh-responsive-image
/// @require {mixin} gh-responsive-image

@mixin resp-img() {
  @include gh-responsive-image();
}
