.node-angebotsprodukt.view-mode-full {
  @extend %clearfix;

  @include breakpoint(null, $bp-s) {
    .group-left {
      margin-bottom: $dist;
    }
  }

  @include breakpoint($bp-s, null) {
    .group-left {
      @include span(8);
    }

    .group-right {
      @include span(4 last);
    }
  }

  .group-right {
    box-shadow: 0 0 10px $shadow-color;
    background-color: $box-color;
    padding: gutter(12);
  }

  .field-name-field-bild {
    margin-bottom: $dist * .66;
    box-shadow: 0 0 10px $shadow-color;

    img {
      @include resp-img;
    }
  }

  .field-name-field-artikelnummer,
  .field-name-field-herstellernummer {
    line-height: $line-height;

    .label-inline {
      display: inline-block;
    }
  }

  .field-name-body {
    margin-top: 1em;
    @extend %formatted-text;
  }

  .field-name-field-gueltigkeit {
    display: none;
  }

  .field-name-field-preis {
    color: $highlight-red;
    font-size: $fs-medium;
    font-weight: $fw-bold;
    border-bottom: solid 2px $border-color;
    margin-bottom: $dist * .66;
    padding-bottom: $dist * .66;
  }

  .field-name-field-dateianhaenge {
    span.file {
      display: block;

      img {
        display: none;
      }

      a {
        @include icon-before($fa-var-cloud-download);
        display: block;

        &:before {
          top: 5px;
        }
      }
    }
  }

  .field-name-custom-node-angebot-anfrage-butt {
    text-align: left;
    margin-top: $dist * .66;

    a {
      @extend %button;
    }
  }
}
