////
/// @author Max Bachhuber
/// @group grasenhiller
////

/// Clears floating of inner containers.
///
/// @example scss - Basic usage
///   .views-content {
///     @extend %clearfix;
///   }

%clearfix {
  @include clearfix;
}

