.node-mitarbeiter.view-mode-mini_teaser {
  position: relative;

  .field {
    display: inline-block;
  }

  .field-name-field-bild {
    border-radius: 100%;
    margin-right: $dist / 2;
    overflow: hidden;
  }

  .field-name-title {
    font-weight: $fw-bold;
  }

  .field-name-field-abteilung,
  .field-name-field-position {
    &:before{
      content: "–";
      margin: 0 5px;
    }
  }

  .group-name {
    display: inline-block;
    @include vcenter;
  }
}

.node-mitarbeiter.view-mode-teaser {
  .field-name-field-bild {
    margin-bottom: $dist / 2;

    img {
      @include resp-img();
      @include box-shadow(0, 0, 10px, 0, $shadow-color);
    }
  }

  .field-name-ds-titel-position {
    font-weight: $fw-bold;

    .position {
      &:before {
        content: " - ";
      }
    }
  }

  .field-name-field-telefon,
  .field-name-field-mobil,
  .field-name-field-e-mail,
  .field-name-field-fax {
    @include icon-before($fa-var-phone);

    &:before {
      color: $font-color-light;
      top: 5px;
    }
  }

  .field-name-field-mobil:before {
    content: $fa-var-mobile;
  }
  .field-name-field-fax:before {
    content: $fa-var-print;
  }
  .field-name-field-e-mail:before {
    content: $fa-var-paper-plane-o;
  }

}

.node-mitarbeiter.view-mode-teaser_2 {
  .group-left {
    @include span(2 of 6);
  }
  .group-right {
    @include span(4 of 6 last);
  }

  .field-name-field-bild {
    img {
      @include resp-img;
      @include box-shadow(0, 0, 10px, 0, $shadow-color);
    }
  }

  .field-name-field-position {
    margin-bottom: $dist / 2;
  }

  .field-name-field-telefon,
  .field-name-field-mobil,
  .field-name-field-e-mail,
  .field-name-field-fax {
    @include icon-before($fa-var-phone);

    &:before {
      color: $font-color-light;
      top: 5px;
    }
  }

  .field-name-field-mobil:before {
    content: $fa-var-mobile;
  }

  .field-name-field-fax:before {
    content: $fa-var-print;
  }

  .field-name-field-e-mail:before {
    content: $fa-var-paper-plane-o;
  }

  .field-name-field-e-mail {
    @include gh-truncate;
  }
}

.node-mitarbeiter.view-mode-teaser_3 {
  @extend %clearfix;

  .group-left {
    float: left;
    margin-right: 20px;
  }

  .group-right {
    float: right;
  }

  .field {
    line-height: $line-height;
  }

  .field-name-field-bild {
    img {
      box-shadow: 0 0 10px $shadow-color;
    }
  }

  .field-name-title {
    font-weight: $fw-bold;
    margin-bottom: $dist / 2;
  }

  .field-name-field-telefon,
  .field-name-field-mobil,
  .field-name-field-e-mail,
  .field-name-field-fax {
    @include icon-before($fa-var-phone);

    &:before {
      color: $highlight-color;
      top: 5px;
    }
  }

  .field-name-field-mobil:before {
    content: $fa-var-mobile;
  }

  .field-name-field-fax:before {
    content: $fa-var-print;
  }

  .field-name-field-e-mail:before {
    content: $fa-var-paper-plane-o;
  }
}

.node-mitarbeiter.view-mode-full {
  @extend %clearfix;

  @include breakpoint(null, $bp-s) {
    .group-left {
      margin-bottom: $dist;
    }
  }

  @include breakpoint($bp-s, null) {
    .group-left {
      @include span(4);
    }

    .group-right {
      @include span(8 last);
    }
  }

  .field-name-field-bild {
    img {
      @include resp-img;
    }
  }

  .field-name-field-telefon,
  .field-name-field-mobil,
  .field-name-field-e-mail,
  .field-name-field-fax {
    @include icon-before($fa-var-phone);
    line-height: $line-height;

    &:before {
      color: $font-color-light;
      top: 5px;
    }
  }

  .field-name-field-mobil:before {
    content: $fa-var-mobile;
  }

  .field-name-field-fax:before {
    content: $fa-var-print;
  }

  .field-name-field-e-mail:before {
    content: $fa-var-paper-plane-o;
  }

  .field-name-field-zustaendigkeiten {
    margin-top: $dist;

    .label-above {
      @extend %sidebar-heading;
    }

    ul.shs-hierarchy {
      li {
        display: inline-block;

        &:after {
          @include fontawesome($fa-var-chevron-right);
          font-size: .6em;
          position: relative;
          top: -1px;
        }

        &.last:after {
          display: none;
        }
      }
    }
  }
}
