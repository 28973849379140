.view-referenzen {
  .view-content {
    @extend %clearfix;

    .views-row {
      $grid-config: (
        (null, $bp-s, 1, 4),
        ($bp-s, $bp-m, 2, 2),
        ($bp-m, null, 3)
      );

      @include resp-gal($grid-config);
    }
  }
}
