.bean-banner {
  .flexslider {
    border: none;
    height: 100%;
    margin-bottom: 0;

    ul {
      height: 100%;

      li {
        height: 100%;

        div.node {
          height: 100%;

          .group-wrapper {
            width: 100%;
            max-width: $max-width;
            @include gh-center('h');
            bottom: $dist;
          }

          .field-name-field-titel,
          .field-name-field-subheadline {
            .field-item {
              display: inline-block;
              background: $action-color;
              color: #FFF;

            }
          }

          .field-name-field-titel {
            font-size: $fs-bigger;

            .field-item {
              padding: 15px 20px;
            }
          }

          .field-name-field-subheadline {
            margin-top: .5em;

            .field-item {
              padding: 10px 15px;
              background: lighten($action-color, 10%);
            }
          }

          .field-name-field-link {
            a {
              position: absolute;
              right: 0;
              bottom: 0;
              @extend %button;
            }
          }
        }
      }
    }

    .flex-direction-nav {
      .flex-next,
      .flex-prev {
        &:before {
          @include fontawesome($fa-var-chevron-right);
          color: #FFF;
          text-shadow: none;
        }

        display: block;
        opacity: 1;
      }

      .flex-prev:before {
        content: $fa-var-chevron-left;
      }
    }
  }
}
