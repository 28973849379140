.node-video {
  .field-name-field-video {
    box-shadow: 0 0 10px $border-color;

    a {
      display: block;
      position: relative;

      &:after {
        @include fontawesome($fa-var-play);
        color: #FFF;
        width: 50px;
        height: 50px;
        line-height: 50px;
        text-align: center;
        background: $highlight-color;
        @include gh-center('b');
        transition: background $duration;
      }

      &:hover:after {
        background: $action-color;
      }
    }

    img {
      @include resp-img;
    }
  }

  .field-name-title h2 {
    font-size: $fs-big;
    font-weight: $fw-bold;
    margin-top: $dist / 2;

    &:before {
      content: "";
      display: inline-block;
      width: 10px;
      height: 10px;
      background: $highlight-color;
      margin-right: 10px;
      position: relative;
      top: -2px;
    }
  }
}
