.paragraphs-item-liste-mit-details {
  .field-collection-view {
    margin-bottom: $dist / 2;

    .group-title-wrapper {
      @extend %clearfix;
      margin-bottom: .5em;

      i.fa {
        float: left;
        width: 35px;
        font-size: $fs-medium;
        color: $highlight-color;
      }

      .field-name-field-titel {
        font-size: $fs-medium;
        font-weight: $fw-bold;
      }
    }

    .field-name-field-text {
      margin-left: 35px;
    }
  }
}
