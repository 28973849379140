// - Größen
$max-width: 1200px;
$column-width: 67px;
$gutter-width: 36px;
$dist: $gutter-width;
$pushy-width: 290px + $gutter-width;
$border-radius: 4px;
$duration: .4s;

// - Farben
$font-color: #001e3c;
$font-color-light: #c7cfd7;
$font-color-footer: #bec7d0;
$highlight-color: #007fff;
$action-color: #001e3c;
$action-color-hover: darken($action-color, 10%);
$button-secondary-color: #dee3e7;
$button-secondary-color-hover: darken($button-secondary-color, 10%);
$accent-color: #f02818;
$border-color: #c7cfd7;
$border-color-footer: #003b77;
$box-color: #eff1f3;
$shadow-color: rgba(0,0,0,.15);
$valid-color: #81dd1f;
$invalid-color: #dd3e1f;
$pushy-bg-color: #333333;
$pushy-main-link-color: #cccccc;
$pushy-sub-link-color: darken($pushy-main-link-color, 10%);
$highlight-red: #f12818;

// - Fonts
$ff-default: "Noto Sans Cond", sans-serif;
$ff-headline: $ff-default;

$fs-root: 16px;
$fs-small: 0.75rem;
$fs-regular: 1rem;
$fs-medium: 1.125rem;
$fs-big: 1.25rem;
$fs-bigger: 1.875rem;

$fw-light: 400;
$fw-regular: 400;
$fw-medium: 700;
$fw-bold: 700;

$line-height: 1.5em;
$line-height-int: 1.5;

// - Breakpoints
//         s        m        l
// --------|--------|--------|--------
// So you have 4 different states.
// From          null  to  breakpoint s  (mobile)
// From  breakpoint s  to  breakpoint m  (10" tablet portrait)
// From  breakpoint m  to  breakpoint s  (10"tablet landscape or small desktop)
// From  breakpoint l  to  null          (desktop)
$bp-s: 480px;
$bp-m: 768px;
$bp-l: 1036px;

// - Susy
$susy: (
  global-box-sizing: border-box,
  columns: 12,
  gutters: $gutter-width/$column-width,
  math: fluid,
  output: float,
  last-flow: from
);

@function span-breite($cols) {
  @return $cols * $column-width + ($cols - 1) * $gutter-width;
}
