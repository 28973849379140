.block-mailchimp-signup {
  margin-top: $dist;
  background: $highlight-color;
  padding: calc(#{gutter()} - ( #{$dist} / 2 ));

  @include breakpoint(null, $bp-m) {
    padding-top:  $dist / 2;
    padding-bottom: $dist / 2;
  }

  & > h2 {
    color: #FFF;
    padding: 0 $dist / 2;
    margin-bottom: 0;

    @include breakpoint(null, $bp-m) {
      margin-bottom: 1em;
    }
  }

  .mailchimp-newsletter-mergefields {
    @include breakpoint(null, $bp-s) {
      padding: 0 $dist / 2;

      & > div {
        margin-bottom: .5em;
      }
    }

    @include breakpoint($bp-s, $bp-m) {
      @include clearfix;
      padding: 0 $dist / 2;

      & > div {
        @include span(6);
        margin-bottom: .5em;

        &:nth-child(2n+2) {
          @include last;
        }
      }
    }

    @include breakpoint($bp-m, null) {
      display: flex;
      justify-content: space-between;

      & > div {
        flex: 1;
        padding: $dist / 2;
      }
    }

    input {
      width: 100%;
    }

    input[type="submit"] {
      @extend %button-secondary;
      padding: 7px;
      line-height: $line-height;
    }
  }
}

#block-block-1 {
  margin-top: $dist;
  width: 100%;
}
