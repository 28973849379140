.page-header {
  margin-bottom: $dist;
}

header.top {
  background: $action-color;
  padding: 15px 0;
  color: $font-color-light;
  @extend %clearfix;

  @include breakpoint(null, 650px) {
    display: none;
  }

  .left {
    float: left;

    @include breakpoint(null, $bp-m + 100px) {
      display: none;
    }
  }

  .right {
    float: right;
  }

  .top-nav > ul.menu {
    & > li {
      display: inline-block;
      padding-right: 10px;
      border-right: solid 1px $highlight-color;
      margin-right: 10px;
      line-height: 1em;
      position: relative;

      &:last-child {
        padding-right: 0;
        border-right: none;
        margin-right: 0;
      }

      & > a {
        color: $font-color-light;
        line-height: 1em;

        &:hover,
        &.active,
        &.active-trail {
          text-decoration: none;
          border-bottom: solid 1px $highlight-color;
        }
      }

      ul.menu {
        display: none;
        width: 220px;
        background: lighten($action-color, 10%);
        @include gh-center('h');
        top: 100%;
        z-index: 502;
        padding: 10px;
        box-shadow: 0 0 10px rgba(0,0,0,.25);

        li {
          margin: 0;
          padding: 0;
          text-align: center;
          line-height: 2em;

          a {
            color: #FFF;
          }
        }
      }

      &:hover {
        ul.menu {
          display: block;
        }
      }
    }
  }
}
