.paragraphs-item-download-liste {
  .field-name-field-dateien {
    padding: $dist;
    background-color: $box-color;

    span.file {
      margin-bottom: 10px;

      &:last-of-type {
        margin-bottom: 0;
      }

      img {
        display: none;
      }

      a {
        display: block;
        @include icon-before($fa-var-cloud-download);

        &:before {
          top: 5px;
        }
      }
    }
  }
}
