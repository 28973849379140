////
/// @author Max Bachhuber
/// @group grasenhiller
////

/// Converts pixel to rem with 16px as base font size.
///
/// @param {Pixel} $pixel - The pixels which should be converted.
/// @return {Rem} The pixel value in rem.
///
/// @example scss - Basic usage
///   a {
///     font-size: gh-rem(20px);
///   }

@function gh-rem($pixel) {
  @return $pixel / 16px + rem;
}
