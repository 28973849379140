.node-termin.view-mode-full {
  .field-name-body {
    @extend %formatted-text;
  }

  .field-name-custom-node-termin-anmeldeformul {
    margin-top: $dist;
    .node > h2 {
      display: none;
    }
  }

  .field-name-field-dateianhaenge {
    padding: $dist / 2;
    background-color: $box-color;
    margin-top: $dist;

    span.file {
      margin-bottom: 10px;

      &:last-of-type {
        margin-bottom: 0;
      }

      img {
        display: none;
      }

      a {
        display: block;
        @include icon-before($fa-var-cloud-download);

        &:before {
          top: 5px;
        }
      }
    }
  }
}

#block-ds-extras-ds-termin-sidebar-blocks {
  .field {
    margin-bottom: $dist;

    &:last-child {
      margin-bottom: 0;
    }

    .label-above {
      @extend %sidebar-heading;
    }

    &.field-name-field-daten {
      .label-above {
        @include icon-before($fa-var-calendar);

        &:before {
          top: 0;
        }
      }

      li {
        & > .date-display-single,
        & > .date-display-range {
          &:after {
            content: " Uhr";
          }

          &.crossed-out {
            text-decoration: line-through;

            .date-display-range {
              text-decoration: line-through;
            }
          }

          .date-display-range {
            display: inline-block;
          }
        }
      }

    }

    &.field-name-field-veranstaltungsort {
      .label-above {
        @include icon-before($fa-var-map-marker);

        &:before {
          top: 0;
        }
      }
    }

    &.field-name-custom-node-termin-call-to-actio {
      text-align: center;
    }
  }
}
