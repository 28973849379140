.view-kompetenz-eintr-ge {
  .view-content {
    @extend %clearfix;

    .views-row {
      $conf: (
        (null, $bp-s, 1),
        ($bp-s, $bp-m, 2),
        ($bp-m, $bp-l, 3),
        ($bp-l, null, 4),
      );

      @include gh-responsive-gallery($conf);
    }
  }
}
