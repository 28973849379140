.merkzettel {
  li {
    padding-top: 0;

    .sub {
      font-size: $fs-small * 1.2;
      color: darken($font-color-light, 10%);
    }
  }
}
