////
/// @author Max Bachhuber
/// @group grasenhiller
////

/// Truncate a text and add an ellipsis

@mixin gh-truncate() {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
