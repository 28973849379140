.bean-c2a-block {
  .field-name-field-link {
    margin-top: $dist;
    text-align: center;

    a {
      @extend %button;
    }
  }
}
