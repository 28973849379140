.paragraphs-item-call-to-action {
  .field-name-field-link {
    text-align: center;
    position: relative;

    &:before {
      content: "";
      height: 1px;
      width: 100%;
      background: $font-color-footer;
      @include gh-center("v");
      left: 0;
      z-index: -10;
    }

    a {
      @extend %button;
    }
  }
}
