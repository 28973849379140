.view-ansprechpartner {
  .view-filters {
    margin-top: $dist;
    padding-bottom: $dist;
    border-bottom: solid 1px $border-color;
    margin-bottom: $dist;

    .views-exposed-widgets {
      position: relative;
    }

    .gh-filter-widget {
      float: left;
      width: 100%;
      position: relative;

      @include breakpoint(null, $bp-l) {
        width: 90%;
      }

      @include breakpoint(null, $bp-s) {
        width: 100%;
      }

      label {
        float: left;
        @include gh-center('v');
        font-size: $fs-medium;
        font-weight: $fw-bold;

        @include breakpoint(null, $bp-l) {
          float: none;
          width: 100%;
          position: static;
          transform: none;
          margin-bottom: 20px;
          display: block;
        }
      }

      .gh-filter {
        float: right;

        @include breakpoint(null, $bp-l) {
          float: none;
        }

        select {
          padding: 10px;
          background: $box-color;
          margin-left: 10px;

          @include breakpoint(null, $bp-s) {
            margin-left: 0;
            width: 100%;
            margin-top: 10px;

            &:first-child {
              margin-top: 0;
            }
          }

          &:nth-of-type(1) {
            margin-left: 0;
          }

          &[disabled] {
            opacity: .5;
          }
        }
      }
    }

    .views-widget-filter-field_zust_ndigkeits_gruppe__value,
    .views-submit-button {
      display: none;
    }
  }

  .view-content {
    .views-content-group {
      & > h1 {
        padding-bottom: $dist / 2;
        border-bottom: solid 2px $highlight-color;
        margin-bottom: $dist;
        margin-top: $dist * 2;
      }

      &:first-child > h1 {
        margin-top: 0;
      }

      .wrapper {
        @extend %clearfix;

        .views-row {
          $grid-config: (
            (null, $bp-l, 1),
            ($bp-l, null, 2)
          );

          @include resp-gal($grid-config);
          border-top: dashed 1px $border-color;
          padding-top: $dist;
          margin-top: $dist !important;

          @include breakpoint(null, $bp-l) {
            &:nth-child(1) {
              border-top: none;
              padding-top: 0;
              margin-top: 0 !important;
            }
          }

          @include breakpoint($bp-l, null) {
            &:nth-child(1),
            &:nth-child(2) {
              border-top: none;
              padding-top: 0;
            }
          }
        }
      }
    }
  }
}

.page-ansprechpartner {
  h1.title {
    margin-bottom: 0;
    border-bottom: none;
    padding-bottom: 0;
  }
}
