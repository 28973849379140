// Set box-sizing to border-box
*,
*:after,
*:before {
  @include box-sizing(border-box);
}

// Prepare for usage of rem
html {
  font-size: $fs-root;
}

// Disable text-zooming on mobile devices
body {
  text-size-adjust: none;
  -webkit-text-size-adjust: none;
  -moz-text-size-adjust: none;
  -ms-text-size-adjust: none;
  -webkit-tap-highlight-color: rgba(0,0,0,0);
  -webkit-font-smoothing: antialiased;
}


// Fix small gutters of images
img {
  vertical-align: top;
}

// Bold elements
h1, h2, h3, h4, h5, h6,
strong, b {
  font-weight: $fw-bold;
}

// - Small text
small {
  font-size: $fs-small;
}

// - Lineheight
h1, h2, h3, h4, h5, h6,
strong, p, b, em, i, span, a, small, th, td, ul, ol {
  line-height: $line-height;
}


// Colorbox
#cboxNext, #cboxClose, #cboxPrevious {
  border: none;
}

// BrowserHappy
#browserhappy {
  margin: 20px auto;
  width: 980px;
  padding: 10px;
  text-align: center;
  background: #FFE4E4;
  border: solid 1px #F00;

  h1 {
    font-size: 16px;
    margin: 0 0 10px 0;
  }

  h2 {
    font-size: 13px;
    margin: 0;
  }
}

