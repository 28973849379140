footer.page-footer-teaser {
  margin-top: $dist;

  @include breakpoint(null, $bp-l) {
    display: none;
  }
}

footer.page-footer {
  background: $action-color;
  color: $font-color-footer;


  @include breakpoint(null, $bp-l) {
    margin-top: $dist;
  }

  a {
    color: $font-color-footer;
  }

  .top,
  .bottom {
    padding: $dist 0;
  }

  .bottom {
    border-top: solid 1px $border-color-footer;
  }

  @include breakpoint(null, $bp-m) {
    .left {
      margin-bottom: 1em;
    }
  }

  @include breakpoint($bp-m, null) {
    .left {
      float: left;
    }

    .right {
      float: right;
    }
  }

  img.logo {
    width: 106px;
    height: 22px;
  }

  ul.menu {
    margin: 0;
    padding: 0;

    li {
      float: left;
      margin-right: $dist / 2;
      border-right: solid 1px $highlight-color;
      padding-right: $dist / 2;

      &.last {
         margin-right: 0;
        border-right: none;
        padding-right: 0;
      }
    }
  }

  .region-socialmedia-menu {
    ul.menu {
      a {
        display: inline-block;

        &:before {
          top: 5px !important;
        }

        &[href*="youtube"] {
          @include icon-before($fa-var-youtube, 1em, .2em);
        }

        &[href*="twitter"] {
          @include icon-before($fa-var-twitter, 1em, .2em);
        }

        &[href*="facebook"] {
          @include icon-before($fa-var-facebook, 1em, .2em);
        }

        &[href*="xing"] {
          @include icon-before($fa-var-xing, 1em, .2em);
        }
      }
    }
  }
}
