body {
  position: relative;
}

.merkzettelInfo {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0,0,0,.9);
  z-index: 499;
  color: #FFF;

  .content {
    max-width: $max-width;
    width: 90%;
    @extend %clearfix;
    margin: 100px auto 0;

    @include breakpoint(370px + ($gutter-width * .8) * 2, null) {
      margin-top: 0;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }

    .text {
      text-align: center;

      @include breakpoint(370px + ($gutter-width * .8) * 2, null) {
        width: 50%;
        float: left;
      }

      h1 {
        font-size: $fs-medium;

        @include breakpoint(370px + ($gutter-width * .8) * 2, null) {
          font-size: $fs-medium;
        }
      }

      p {
        text-align: center;
      }

      .button-wrapper {
        margin-top: $gutter-width;
      }
    }

    i.fa {
      font-size: 50px;
      margin-top: 20px;
      position: absolute;
      left: 50%;
      @include transform(translateX(-50%) rotate(90deg));


      @include breakpoint(370px + ($gutter-width * .8) * 2, null) {
        float: left;
        transform: rotate(45deg);
        font-size: 125px;
        margin-left: 80px;
        margin-top: 0;
        bottom: -50%;
      }
    }
  }
}

.merkzettel {
  position: fixed;
  bottom: 0;
  width: 100%;
  background: $box-color;
  z-index: 500;
  padding: $gutter-width / 2;
  box-shadow: 0 0 10px 0 rgba(0,0,0,.25);

  @include breakpoint(370px + ($gutter-width * .8) * 2, null) {
    max-width: 370px;
    right: $gutter-width * 3;
    padding: $gutter-width / 3 * 2;
  }

  &.minimized {
    cursor: pointer;

    h3 {
      margin-bottom: 0;

      i {
        &:before {
          content: $fa-var-chevron-up;
        }
      }
    }

    ul {
      display: none;
    }

    a.button {
      display: none;
    }
  }

  h3 {
    margin-bottom: $gutter-width / 2;
    font-size: $fs-regular;

    @include breakpoint(370px + ($gutter-width * .8) * 2, null) {
      margin-bottom: $gutter-width * .8;
      font-size: $fs-medium;
    }

    i {
      float: right;
      cursor: pointer;
    }
  }

  ul {
    margin-top: 0;
  }

  a {
    padding: .4em 1em;
    margin-top: $gutter-width * .8;
    display: block;
    text-align: center;
  }

  ul {
    li {
      position: relative;
      padding: .5em 0;

      i {
        float: right;
        margin-left: 10px;
        cursor: pointer;
      }
    }
  }
}
