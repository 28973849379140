.view-termine-workshops.view-display-id-page {
  $grid-config: (
    (null, $bp-m, 1),
    ($bp-m, null, 2)
  );

  $datum-overlay-width: 80px;

  .view-content {
    @extend %clearfix;

    .views-row {
      @include resp-gal($grid-config);
      background: $box-color;
      padding: $dist $dist $dist ($datum-overlay-width - $dist / 2) + $dist;
      position: relative;

      .views-field-nothing {
        width: $datum-overlay-width;
        background: $highlight-color;
        padding: 20px 10px;
        position: absolute;
        top: $dist / 2;
        left: - $dist / 2;
        color: #FFFFFF;
        text-align: center;
      }

      .views-field-field-veranstaltungsort {
        margin-top: 10px;
        @include icon-before($fa-var-map-marker, 1em, 0);

        &:before {
          top: 0;
        }
      }

      .views-field-body {
        margin-top: $dist;
      }

      .views-field-nid {
        margin-top: $dist;
      }
    }
  }
}

.view-termine-workshops.view-display-id-block_1 {
  .views-row {
    border-bottom: dashed 1px $border-color;
    padding-bottom: $dist / 2;
    margin-bottom: $dist / 2;

    .views-field-title {
      a {
        font-size: $fs-big;
        font-weight: $fw-bold;
        line-height: $line-height;

        &:after {
          @include fontawesome($fa-var-long-arrow-right);
          color: $highlight-color;
          margin-left: 10px;
        }
      }
    }

    .views-field-field-teaser {
      margin: .5em 0;
      line-height: $line-height;
    }

    .views-field-field-daten-1 {
      color: $highlight-red;
      @include icon-before($fa-var-clock-o);

      &:before {
        top: 4px;
      }
    }
  }
}
