#block-views-blogkategorien-block,
#block-views-blog-filter-jahr-block,
#block-views-blogkategorien-block-1 {
  h2 {
    @include icon-before($fa-var-folder);
  }

  a.active {
    color: $highlight-color;
  }
}

#block-views-blog-filter-jahr-block h2:before {
  content: $fa-var-archive;
}

#block-views-blogkategorien-block-1 h2:before {
  content: $fa-var-tags;
}
