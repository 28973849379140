////
/// @author Max Bachhuber
/// @group grasenhiller
////

/// Displays elements in a grid.
///
/// @param {Number} $columns - The number of items in a row.
/// @param {Number} $of [12] - How many columns is the surrounding container.
/// @require {mixin} http://susydocs.oddbird.net/en/latest/toolkit/#span-mixin <http://susydocs.oddbird.net/en/latest/toolkit/#span-mixin>
///
/// @example scss - Basic usage
///   .views-row {
///     @include gh-gallery(6);
///   }
///
/// @example scss - Usage with of
///   .views-row {
///     @include gh-gallery(6, 9);
///   }

@mixin gh-gallery($columns, $of: 12, $vertical-gutter-multiplicator: 1) {
  @include span(($of / $columns) of $of);
  margin-top: gutter($of) * $vertical-gutter-multiplicator;

  &:nth-child(-n + #{$columns}) {
    margin-top: 0;
  }

  &:nth-child(#{$columns}n+#{$columns}) {
    @include last;
  }

  &:nth-child(#{$columns}n+#{$columns + 1}) {
    @include break;
  }
}
