.page-floating {
  position: fixed;
  bottom: $dist;
  right: $dist;
  z-index: 499;

  .field-name-field-ansprechpartner {
    background: $box-color;
    padding: 30px 20px;
    position: relative;
    display: none;
    box-shadow: 0 0 10px 0 $shadow-color;

    i.fa-close {
      position: absolute;
      top: 15px;
      right: 10px;
      cursor: pointer;
    }

    &.visible {
      display: block;
    }

    &:after {
      bottom: -20px;
      right: 16px;
      border: solid transparent;
      content: " ";
      height: 0;
      width: 0;
      position: absolute;
      pointer-events: none;
      border-color: rgba(136, 183, 213, 0);
      border-top-color: $box-color;
      border-width: 10px;
      margin-left: -10px;
    }
  }

  .field-name-custom-kompetenz-fab {
    text-align: right;
    margin-top: $dist / 2;

    a.fab-toggle {
      display: inline-block;
      width: 50px;
      height: 50px;
      padding: 0;
      position: relative;
      border-radius: 50%;

      i {
        @include gh-center('b');
      }
    }
  }
}
