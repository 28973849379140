.bean-2-teaser {
  position: relative;

  &:after {
    content: "";
    display: block;
    width: 100%;
    position: absolute;
    height: 10px;
    background: rgba($highlight-color, .9);
    bottom: 0;
    z-index: 499;
    visibility: visible;
  }

  .field-name-field-bild-teaser-1 {
    img {
      @include resp-img();
    }
  }

  .group-container {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 50%;
    @include transform(translateX(-50%));
  }

  .group-teaser-1 {
    text-align: center;
    position: absolute;
    top: $dist;
    bottom: $dist;
    left: 0;
    right: 0;

    .field-name-field-titel-teaser-1 {
      span {
        display: inline-block;
        font-size: $fs-big;
        font-weight: $fw-bold;
        color: #FFF;
        background: rgba($action-color, 0.9);
        padding: 15px 20px;
      }
    }

    .field-name-field-subheadline-teaser-1 {
      span {
        display: inline-block;
        color: #FFF;
        background: rgba($accent-color, 0.9);
        padding: 8px 18px;
      }
    }

    .field-name-field-link-teaser-1 {
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      text-align: center;

      a {
        @extend %button;
      }
    }
  }

  .group-teaser-2 {
    position: absolute;
    top: 50%;
    right: 0;
    @include transform(translateY(-50%));
    color: #FFF;
    background: $accent-color;
    border-radius: 100%;
    width: 190px;
    height: 190px;
    text-align: center;

    a {
      color: #FFF;
      text-decoration: none;

      &:hover {
        color: #FFF;
        text-decoration: none;
      }
    }

    .field-name-field-titel-teaser-2 {
      font-size: $fs-big;
      font-weight: $fw-bold;
      padding: 8px 0;
      border-top: solid 2px #FFF;
      border-bottom: solid 2px #FFF;
      display: inline-block;
      width: 140px;
      margin-top: 45px;
    }

    .field-name-field-subheadline-teaser-2 {
      width: 125px;
      display: inline-block;
      font-weight: $fw-bold;
      line-height: 24px;
      margin-top: $dist / 3;
    }

    .field-name-field-link-teaser-2 {
      a {
        font-family: fontawesome;
        color: #fff;
        font-weight: $fw-bold;
        font-size: gh-rem(26px);
        transition: transform $duration;
        display: block;

        &:hover {
          text-decoration: none;
          transform: scale(1.3);
        }
      }
    }
  }
}
