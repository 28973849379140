.paragraphs-item-teaser {
  .field-name-custom-teaser-linked-image {
    a {
      @include gh-image-hover($fa-var-link);
      img {
        @include resp-img();
      }
    }
  }

  .field-name-field-bild {
    a {
      @include gh-image-hover($fa-var-arrow-right, $highlight-color, left);

      &:after {
        text-shadow: 2px 1px rgba(0,0,0,.25);
      }

      &:hover {
        outline: solid 5px $highlight-color;

        img {
          opacity: 1;
        }
      }
    }

    img {
      @include resp-img();
      box-shadow: 0 0 10px $shadow-color;
    }
  }

  .field-name-custom-teaser-linked-title {
    font-size: $fs-big;
    font-weight: $fw-bold;
    margin-top: $dist / 2;

    &:before {
      content: "";
      display: inline-block;
      width: 10px;
      height: 10px;
      background: $highlight-color;
      margin-right: 10px;
      position: relative;
      top: -2px;
    }
  }
}
