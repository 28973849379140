.content-wrapper.has-sidebar {
  @extend %clearfix;

  main.page-main {
    @include breakpoint(null, $bp-m) {
      margin-bottom: $dist;
    }
  }

  &.sidebar-right {
    main.page-main {
      @include breakpoint($bp-m, null) {
        @include span(8);
      }
    }
  }

  &.sidebar-left {
    main.page-main {
      @include breakpoint($bp-m, null) {
        @include span(8 last);
      }
    }
  }

  aside.page-sidebar {
    background: $box-color;

    @include breakpoint(null, $bp-m) {
      padding: $dist / 2;
    }

    @include breakpoint($bp-m, null) {
      padding: gutter(12);
      @include span(4 last);
    }

    .block {
      margin-bottom: $dist;

      &:last-child {
        margin-bottom: 0;
      }

      & > h2 {
        @extend %sidebar-heading;
      }
    }
  }

  aside.page-sidebar-left {
    background: $box-color;

    @include breakpoint(null, $bp-m) {
      margin-bottom: $dist;
    }

    @include breakpoint(null, $bp-m) {
      padding: $dist / 2;
    }

    @include breakpoint($bp-m, null) {
      padding: gutter(12);
      @include span(4);
    }

    .block {
      margin-bottom: $dist;

      &:last-child {
        margin-bottom: 0;
      }

      & > h2 {
        @extend %sidebar-heading;
      }
    }
  }
}
