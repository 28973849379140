.view-preisaenderungen {
  caption {
    .field-name-field-logo {
      margin-bottom: 18px;
    }

    .field-name-field-logo + .field-name-title {
      display: none;
    }
  }

  .views-field-field-datum {
    width: percentage(221 / 1200);
  }

  .views-field-title {
    width: percentage(813 / 1200);
  }

  .views-field-field-prozentsatz {
    width: percentage(165 / 1200);
  }
}
