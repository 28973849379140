.view-tourenplaene {
  margin-bottom: $dist;

  .view-content {
    @extend %clearfix;
  }
  .views-row {
    background: $box-color;
    padding: $dist / 2;
    text-align: center;

    $config : (
      (null, $bp-m, 1),
      ($bp-m, null, 2)
    );
    @include gh-responsive-gallery($config);

    .views-field-field-gueltigkeit2 {
      .views-label {
        display: inline-block;
        margin-right: 1em;
      }

      .field-content {
        display: inline-block;

        a {
          display: block;
          @include icon-before($fa-var-cloud-download);

          &:before {
            top: 5px;
          }
        }
      }
    }
  }
}
