.page-produktuebersicht {
  .content-wrapper.has-sidebar aside.page-sidebar-left {

    @include breakpoint(null, $bp-m) {

      &:before {
        content: " Filtern & Suchen";
        font-weight: $fw-bold;
        text-align: center;
        font-family: FontAwesome, 'Noto Sans Cond', sans-serif;
        display: block;
        cursor: pointer;
      }

      .block {
        display: none;
      }
    }

    .block {
      margin-bottom: 1em;
    }

    #block-views-exp-produkt-bersicht-page {
      label {
        font-weight: $fw-bold;
        margin-bottom: .5em;
        margin-top: 1em;
        display: block;

        &:after {
          content: ":";
        }
      }

      .description {
        font-size: $fs-small;
        //color: $font-color-light;
        margin-top: .5em;
      }

      .views-submit-button {
        display: none;
      }

      input[type="text"] {
        width: 100%;
      }
    }

    .block-facetapi {
      & > h2 {
        border-bottom: none;
        padding-bottom: 0;
        margin-bottom: .5em;
        font-size: $fs-regular;

        &:after {
          content: ":";
        }
      }

      .content > .item-list > ul > li > .item-list {
        padding-left: 1em;

        & > ul > li > .item-list {
          padding-left: 1em;
        }
      }

      input[type="checkbox"] {
        display: none;
      }

      a.facetapi-checkbox {
        position: relative;
        display: block;

        &:before {
          content: "";
          width: 1.5em;
          height: 1.5em;
          background: #FFF;
          border: solid 1px $border-color;
          display: inline-block;
          position: relative;
          top: 6px;
          border-radius: 3px;
          margin-right: .5em;
          font-size: $fs-regular;
        }
      }

      input[type="checkbox"]:checked + a.facetapi-checkbox {
        display: inline-block !important;
        font-size: 0;

        &:after {
          @include fontawesome($fa-var-check);
          position: absolute;
          font-size: $fs-regular * .9;
          left: .4em;
          top: .7em;
          z-index: 499;
        }
      }
    }
  }
}
