.node-referenz.view-mode-teaser {
  .img-title-wrapper {
    position: relative;
    margin-bottom: 18px;
    box-shadow: 0 0 10px $shadow-color;
  }

  .field-name-field-bild {
    img {
      @include resp-img();
    }
  }

  .field-name-title {
    font-size: $fs-big;
    font-weight: $fw-bold;
    color: #FFF;
    position: absolute;
    bottom: -1px;
    background: rgba($highlight-color, 0.8);
    width: 100%;
    padding: 15px 20px;
    text-align: center;
  }

  .field-name-field-teaser,
  .field-name-body {
    padding-top: $dist;
    border-top: solid 2px $action-color;
    margin-bottom: $dist;
  }
}

#block-ds-extras-referenz-sidebar-contents {
  .label-above {
    @extend %sidebar-heading;
    margin-top: $dist;
  }

  .field:first-child {
    .label-above {
      margin-top: 0;
    }
  }

  .field-name-custom-linked-logo .label-above,
  .field-name-custom-referenz-label-kunde .label-above,
  .field-name-field-kompetenzen .label-above {
    @include icon-before($fa-var-user, .8em);

    &:before {
      top: 0;
    }
  }

  .field-name-custom-referenz-label-kunde .label-above:before {
    content: $fa-var-comments;
  }

  .field-name-field-kompetenzen .label-above:before {
    content: $fa-var-connectdevelop;
  }
  .field-name-custom-linked-logo {
    a {
      text-align: center;
      display: block;

      img {
        @include resp-img;
        max-width: 304px;
      }
    }
  }

  .field-name-field-kundenmeinung-bild {
    float: left;
    margin-right: $dist / 2;

    img {
      box-shadow: 0 0 10px $shadow-color;
    }
  }

  .field-name-field-kundenmeinung-name {
    font-weight: $fw-bold;
    line-height: 1.5em;
    margin-top: 20px;
  }

  .field-name-field-kundenmeinung-position {
    @extend %clearfix;
  }

  .field-name-field-kundenmeinung-zitat {
    margin-top: $dist / 2;
    width: 100%;
    @extend %formatted-text;
    margin-bottom: $dist;
  }

  .field-name-field-kompetenzen {
    ul li {
      @include icon-before($fa-var-circle, .5em);

      &:before {
        top: 10px;
        font-size: 3px;
        left: -10px;
      }
    }
  }
}
