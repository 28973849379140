.paragraphs-item-entity-reference.view-mode-kleines_produkt {
  @extend %clearfix;

  & > .node {
    margin-top: $dist;

    @include breakpoint(null, $bp-m) {
      &:first-child {
        margin-top: 0;
      }
    }

    @include breakpoint($bp-m, null) {
      @include span(6);

      &:nth-child(2n+2) {
        @include last;
      }

      &:nth-child(-n+2) {
        margin-top: 0;
      }
    }
  }
}
