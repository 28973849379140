.paragraphs-item-headline.headline-1 {
  h2 {
    font-size: $fs-bigger;
    font-weight: $fw-bold;
    text-align: center;
    position: relative;
    padding-bottom: $dist / 4;
    margin-bottom: 0;

    &:after {
      content: "";
      width: 100px;
      height: 2px;
      background: $font-color-footer;
      position: absolute;
      left: 50%;
      bottom: 0;
      @include transform(translateX(-50%));
    }
  }
}

.paragraphs-item-headline.headline-2 {
  h2 {
    font-size: $fs-big;
    margin-bottom: 0;
  }
}
