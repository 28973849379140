.view-angebote {
  position: relative;
  .view-content {
    width: 100vw;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);

    @extend %clearfix;

    .views-row {
      position: relative;
      @extend %clearfix;

      @include breakpoint(null, $bp-s) {
        margin-bottom: $dist;

        &:last-of-type {
          margin-bottom: 0;
        }
      }

      @include breakpoint($bp-m, $bp-l) {
        width: 100%;
      }

      @include breakpoint($bp-l, null) {
        float: left;
        width: 50%;
      }

      .views-field-field-bild {
        img {
          @include resp-img;
        }
      }

      .group-top {
        color: #FFF;
        @extend %clearfix;

        @include breakpoint($bp-s, null) {
          position: absolute;
          top: percentage($dist / 300px);
          left: percentage($dist / 800px);
        }
      }

      .views-field-title {
        background: $action-color;
        @include breakpoint($bp-s, null) {
          background: rgba($action-color, .8);
        }
        padding: .5em 1em;
        font-size: $fs-big;
        font-weight: $fw-bold;
      }

      .views-field-field-teaser-info,
      .views-field-unpublish-on {
        display: block;

        @include breakpoint(null, $bp-s) {
          float: left;
          width: 50%;
        }

        .field-content {
          padding: .5em 1em;
          display: inline-block;
          background: $highlight-red;
          line-height: $line-height;

          @include breakpoint(null, $bp-s) {
            width: 100%;
          }

          @include breakpoint($bp-s, null) {
            background: rgba($highlight-red, .8);
          }
        }
      }

      .views-field-unpublish-on {
        .field-content {
          @include breakpoint($bp-s, null) {
            padding: .3em 1em;
          }

          background: darken($box-color, 20%);
          @include breakpoint($bp-s, null) {
            background: rgba(darken($box-color, 20%), .8);
          }
        }
      }

      .views-field-view-node {
        @include breakpoint(null, $bp-s) {
          text-align: center;
          margin-top: 1em;
          //float: right;
        }
        @include breakpoint($bp-s, null) {
          position: absolute;
          bottom: percentage($dist / 300px);
          right: percentage($dist / 800px);
        }

        a {
          @extend %button;
        }
      }
    }

    .more-link {
      width: calc(100% - #{$dist});
      max-width: $max-width;
      margin: 0 auto;

      a {
        margin-top: $dist;
      }
    }
  }
}
