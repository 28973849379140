.paragraphs-item-download {
  background: $box-color;
  padding: $dist / 2;
  position: relative;

  .group-left {
    float: left;
    margin-right: $dist / 2;
  }

  .group-right {
  }

  .field-name-field-bild {
    box-shadow: 0 0 10px $shadow-color;
  }

  .field-name-field-titel {
    font-weight: $fw-bold;
    font-size: $fs-medium;
    margin: $dist / 2 0;
  }

  .field-name-custom-download-button {
    position: absolute;
    bottom: $dist / 2;
    left: 97px + $dist;
  }

  &[max-width~='#{span-breite(4)}'] {
    .field-name-field-bild {
      display: none;
    }

    .field-name-custom-download-button {
      position: static;
    }
  }
}
