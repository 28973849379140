.view {
  ul.pager {
    margin: $dist 0 0 0;
    padding: 0;
    @extend %clearfix;
    text-align: center;

    li {
      display: inline-block;
      padding: 5px 10px;

      &.pager-current {
        background: $action-color;
        color: #FFF;
      }
    }
  }

  .more-link {
    text-align: right;

    a {
      @extend %button;
      @extend %button-secondary;
    }
  }
}
