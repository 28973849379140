////
/// @author Benedikt Hofstätter
/// @author Maximilian Bachhuber
/// @group grasenhiller
////

/// Styles text blocks. Usually used for body fields.
///
/// Removes margin top and bottom
///
/// @example scss - Basic usage
///   .field-body {
///     @extend %formatted-text;
///   }

%formatted-text {
  p {
    margin-top: 1em;

    &:first-of-type {
      margin-top: 0;
    }

    & + ul,
    & + ol,
    & + h1,
    & + h2,
    & + h3,
    & + h4,
    & + h5,
    & + h6,
    & + table,
    & + div[class^="table-"] {
      margin-top: 1em;
    }
  }

  h1, h2, h3, h4, h5, h6 {
    font-family: $ff-headline;
    font-weight: $fw-regular;

    & + p,
    & + ul,
    & + ol,
    & + table,
    & + div[class^="table-"] {
      margin-top: 1em;
    }
  }

  ol {
    margin: 0 0 1em 1.25em;

    & > li {
      list-style-type: disc;
    }
  }

  ol {
    & > li {
      list-style-type: decimal;
    }
  }

  ul, ol {
    list-style: outside;

    & + p,
    & + h1,
    & + h2,
    & + h3,
    & + h4,
    & + h5,
    & + h6,
    & + table,
    & + div[class^="table-"] {
      margin-top: 1em;
    }
  }

  ul {
    list-style: none;

    & > li {
      @include icon-before($fa-var-circle);

      &:before {
        font-size: 6px;
        left: -10px;
        top: 9px;
      }
    }
  }

  a {
    color: $highlight-color;

    &:hover {
      color: darken($highlight-color, 10%);
    }
  }
}
