.paragraphs-item-zahlen-fakten {
  .field-collection-container {
    @extend %clearfix;
    text-align: center;

    .field-collection-view {
      text-align: center;
      margin-top: gutter(12);
      display: inline-block;
      float: none !important;
      vertical-align: top;

      &:first-child {
        margin-top: 0;
      }

      &:last-child {
        margin-right: 0 !important;
      }

      .field-name-field-icon {
        font-size: gh-rem(60px);
        @include border-radius(50%);
        border: solid 1px $border-color;
        width: 137px;
        height: 137px;
        color: $highlight-color;
        position: relative;
        display: inline-block;

        i {
          @include gh-center("b");
        }
      }

      .field-name-field-titel {
        font-size: $fs-big * 1.3;
        font-weight: $fw-medium;
        margin-top: $dist / 2;
        word-break: break-word;
        line-height: $line-height;
      }

      .field-name-field-einzeiliger-text {
        font-size: $fs-medium;
        margin-top: $dist / 2;
        line-height: $line-height
      }
    }
  }

  &[max-width~='#{span-breite(6)}'] {
    .field-collection-container {
      .field-collection-view {
        @include gh-gallery(2);

        .field-name-field-icon {
          font-size: gh-rem(60px);
        }

        .field-name-field-titel {
          font-size: $fs-medium;
        }

        .field-name-field-einzeiliger-text {
          font-size: $fs-regular;
        }
      }
    }
  }

  &[min-width~='#{span-breite(6) + 1px}'][max-width~='#{span-breite(9)}'] {
    .field-collection-container {
      .field-collection-view {
        @include gh-gallery(3);

        .field-name-field-icon {
          font-size: gh-rem(90px);
        }

        .field-name-field-titel {
          font-size: $fs-big;
        }
      }
    }
  }

  &[min-width~='#{span-breite(9) + 1px}'] {
    .field-collection-container {

      .field-collection-view {
        @include gh-gallery(4);
      }
    }
  }
}
