.pushy {
  $menu-entry-height: calc(#{$fs-regular} * #{$line-height-int} + #{$dist} / 1.5 + 1px);
  background: $pushy-bg-color;

  a,
  span.nolink {
    color: $pushy-main-link-color;
  }

  .main-nav {
    // - Level 1
    & > ul {
      & > li {
        &.expanded {
          position: relative;

          & > a,
          & > span.nolink {
            width: calc(100% - #{$dist * 1.5});
          }

          &:after {
            // @include fontawesome($fa-angle-down);
            cursor: pointer;
            content: '+';
            position: absolute;
            top: 0;
            right: 0;
            width: $dist * 1.5;
            height: $menu-entry-height;
            line-height: $menu-entry-height;
            font-weight: $fw-regular;
            font-size: $fs-regular;
            color: #FFF;
            text-align: center;
            background: darken($pushy-bg-color, 10%);
          }

          &.open {
            &:after {
              content: '-';
            }
          }
        }

        & > a,
        & > span.nolink {
          display: block;
          padding: $dist/3 $dist/2;
          font-weight: $fw-regular;
          font-size: $fs-regular;
          border-bottom: none;
          border-top: none;
          text-decoration: none;
          border-bottom: 1px solid darken($font-color, 35%);
        }

        &:hover,
        &.active-trail,
        &.active {
          & > a,
          & > span.nolink {
            color: #ffffff;
            background: $action-color;
            border-bottom: 1px solid $action-color;
            -webkit-box-shadow: inset 10px 0 6px -9px rgba(0, 0, 0, .7);
            -moz-box-shadow: inset 10px 0 6px -9px rgba(0, 0, 0, .7);
            box-shadow: inset 10px 0 6px -9px rgba(0, 0, 0, .7);
          }

          &:after {
            border-bottom: 1px solid darken($pushy-bg-color, 10%);
          }
        }

        // - Level 2
        & > ul {
          display: none;
          background: darken($pushy-bg-color, 5%);
          -webkit-box-shadow: inset 10px 0 6px -9px rgba(0, 0, 0, .7);
          -moz-box-shadow: inset 10px 0 6px -9px rgba(0, 0, 0, .7);
          box-shadow: inset 10px 0 6px -9px rgba(0, 0, 0, .7);

          & > li {
            &.expanded > span.nolink,
            &.expanded > a {
              // - Kategorien
              position: relative;
              display: block;
              padding: $dist/4 $dist/2 !important;
              font-weight: $fw-regular;
              font-size: 1rem;
              border-bottom: none;
              border-top: none;
              text-decoration: none;
              border-bottom: 1px solid darken($font-color, 20%);
              color: $pushy-sub-link-color;

              &:after {
                position: absolute;
                right: $dist/2;
                top: 50%;
                display: block;
                content: ' ';
                width: $dist;
                height: 1px;
                background: $font-color;
              }
            }

            a,
            span.nolink {
              display: block;
              color: $pushy-sub-link-color;
              padding: $dist/4 $dist;
              font-weight: $fw-regular;
              font-size: 1rem;
              border-bottom: none;
              border-top: none;
              text-decoration: none;
              border-bottom: 1px solid darken($font-color, 43%);
            }

            &:hover,
            &.active-trail,
            &.active {
              & > a,
              & > .nolink {
                background: darken($action-color, 10%);
                border-bottom: 1px solid darken($action-color, 10%);
                -webkit-box-shadow: inset 10px 0 6px -9px rgba(0, 0, 0, .7);
                -moz-box-shadow: inset 10px 0 6px -9px rgba(0, 0, 0, .7);
                box-shadow: inset 10px 0 6px -9px rgba(0, 0, 0, .7);
              }
            }

            &:last-of-type {
              a,
              span.nolink {
                border-bottom: 1px solid darken($font-color, 60%);
              }
            }

            // - Level 3
            & > ul.menu {
              li {
                &:hover,
                &.active-trail,
                &.active {
                  & > a,
                  & > span.nolink {
                    background: darken($action-color, 15%);
                    border-bottom: 1px solid darken($action-color, 15%);
                    -webkit-box-shadow: inset 10px 0 6px -9px rgba(0, 0, 0, .7);
                    -moz-box-shadow: inset 10px 0 6px -9px rgba(0, 0, 0, .7);
                    box-shadow: inset 10px 0 6px -9px rgba(0, 0, 0, .7);
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  .top-nav,
  .footer-nav {
    margin-top: 0.5em;
    padding: 0 $dist/2;

    ul {
      @extend %clearfix;

      li {
        display: block;
        float: left;
        width: 49%;

        &:nth-of-type(2n+2) {
          margin-left: 2%;
        }

        a,
        span.nolink {
          display: block;
          padding: 5px 5px;
          font-weight: $fw-regular;
          font-size: 1rem;
          color: $pushy-sub-link-color;
          border-bottom: none;
          border-top: none;
          text-decoration: none;

          &:hover {
            background: none;
            text-decoration: underline;
          }
        }

        &.active-trail,
        &.active {
          a,
          span.nolink {
            color: $action-color;
          }
        }
      }
    }

    li {
      a.active,
      a:hover,
      a.active-trail {
        color: $pushy-sub-link-color !important;
        text-decoration: underline !important;
      }
    }
  }

  .footer-nav > ul.menu {
    border-top: solid 1px darken($font-color, 60%);
    padding-top: $dist / 4;
  }

  .footer-nav ul.menu li:first-of-type {
    display: none;
  }

  .footer-nav ul.menu li:nth-of-type(2) {
    margin-left: 0;
    margin-right: 2%;
  }

  .footer-nav ul.menu li:nth-of-type(4) {
    margin-left: 0;
  }

}
