.node-angebot.view-mode-full {
  .group-top {
    @extend %clearfix;
    background-color: $box-color;
    margin-bottom: $dist;

    .group-left {
      @include span(8);
      padding: gutter(12) 0 gutter(12) gutter(12);
    }

    .field-name-custom-code-angebot-non-cropped- {
      margin-bottom: $dist;
      display: none;

      img {
        @include resp-img;
      }
    }

    .group-rechts {
      @include span(4 last);
      padding: gutter(12);
    }
  }

  .field-name-field-dateianhaenge {
    span.file {
      display: block;

      img {
        display: none;
      }

      a {
        @include icon-before($fa-var-cloud-download);
        display: block;

        &:before {
          top: 5px;
        }
      }
    }
  }

  .field-name-field-angebote {
    & > .node {
      padding-bottom: $dist;
      border-bottom: solid 1px $border-color;
      margin-bottom: $dist;
    }
  }

  .field-name-custom-block-angebotsanfrageform {
    @include breakpoint($bp-m, null) {
      width: 50%;
    }



    .webform-component--interesse-an {
      .angebotsprodukte-select {
        padding: 6px 0;

        .produkt {
          cursor: pointer;
          margin-bottom: .5em;

          i {
            width: 15px;
          }
        }
      }

      input[type="text"] {
        display: none;
      }
    }
  }
}
