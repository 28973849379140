.paragraphs-item-zeitstrahl {
  $circle-size: 26px;
  $circle-inner-size: 10px;
  $zeit-width: 100px;

  position: relative;

  &:before {
    content: '';
    height: 100%;
    border-left: solid 1px $border-color;
    position: absolute;
    top: 0;
    left: $circle-size / 2;
  }

  .field-collection-view {
    padding-bottom: $dist * 0.3;
    padding-left: $circle-size + $dist / 2;

    &:last-child {
      padding-bottom: 0;
    }

    // Highlighted Items
    &.highlighted {
      font-weight: $fw-bold;
    }

    .entity-field-collection-item {
      @extend %clearfix;


      .field {
        float: left;
      }

      .field-name-custom-zeitstrahl-circle {
        margin-right: $dist / 2;
        margin-left: 0 - ($circle-size + $dist / 2);

        .circle {
          display: block;
          width: $circle-size;
          height: $circle-size;
          padding: ($circle-size - 2px - $circle-inner-size) / 2;
          border-radius: 50%;
          border: solid 1px $border-color;
          position: relative;
          background: #FFF;

          .inner {
            display: block;
            width: $circle-inner-size;
            height: $circle-inner-size;
            border-radius: 50%;
            background: $highlight-color;
          }
        }
      }

      .field-name-field-zeit,
      .field-name-field-beschreibung {
        line-height: $circle-size;
      }

      .field-name-field-zeit {
        width: $zeit-width;
      }

      .field-name-field-beschreibung {
        width: calc(100% - #{$zeit-width});
      }
    }
  }

  @include el-query(null, span-breite(6)) {
    .field-collection-view {
      .entity-field-collection-item {
        .field-name-field-zeit {
          float: none;
          margin-bottom: 0;
        }

        .field-name-field-beschreibung {
          width: 100%;
        }
      }
    }
  }
}
