.node-blogartikel.view-mode-teaser {
  .field-name-title {
    a {
      font-size: $fs-big;
      font-weight: $fw-bold;
      line-height: $line-height;

      &:after {
        @include fontawesome($fa-var-long-arrow-right);
        color: $highlight-color;
        margin-left: 10px;
      }
    }
  }

  .field-name-ds-datum-kategorie {
    color: $font-color-light;
    margin-bottom: $dist;
  }

  .field-name-field-bild {
    float: left;
    margin-right: $dist;
    @include box-shadow(0,0,10px,0,$shadow-color);
  }

  .field-name-field-autor {
    margin-top: $dist;
    background: $box-color;
    padding: $dist / 2;
  }
}

.node-blogartikel.view-mode-full {
  & > .group-left {
    @include breakpoint($bp-m, null) {
      @include span(8);
    }
  }

  & > .group-right {
    background: $box-color;
    padding: gutter(12);

    @include breakpoint($bp-m, null) {
      @include span(4 last);
    }

    & > .field {
      margin-bottom: $dist;

      &:last-child {
        margin-bottom: 0;
      }

      & > .label-above {
        padding-bottom: 15px;
        border-bottom: solid 2px $border-color;
        margin-bottom: 15px;
        font-size: $fs-medium;
        font-weight: $fw-bold;
        @include icon-before($fa-var-user, .8em);

        &:before {
          top: 0;
        }
      }
    }
  }

  .field-name-field-autor {
    margin-top: $dist;
    background: $box-color;
    padding: $dist / 2;
  }

  .field-name-custom-disqus {
    margin-top: $dist;
  }

  .field-name-field-tags {
    & > .label-above:before {
      content: $fa-var-tags !important;
    }

    ul {
      @extend %tagliste;
    }
  }

  .field-name-field-verwandte-beitraege {
    & > .label-above:before {
      content: $fa-var-lightbulb-o !important;
    }
  }
}

.node-blogartikel.view-mode-teaser_2 {
  .field-name-title {
    a {
      font-size: $fs-big;
      font-weight: $fw-bold;
      line-height: $line-height;

      &:after {
        @include fontawesome($fa-var-long-arrow-right);
        color: $highlight-color;
        margin-left: 10px;
      }
    }
  }

  .field-name-field-teaser {
    margin: .5em 0;
    line-height: $line-height;
  }

  .field-name-ds-datum-kategorie {
    color: $font-color-light;
  }
}
