.view-blog {
  .views-row {
    padding-bottom: $dist;
    border-bottom: dashed 1px $border-color;
    margin-bottom: $dist;
  }

  &.view-display-id-block_1 {
    .views-row {
      padding-bottom: $dist / 2;
      margin-bottom: $dist / 2;
    }
  }

  &.view-display-id-panel_pane_1 {
    .view-content {
      @extend %clearfix;

      .views-row {
        $config: ((null, $bp-m, 1),($bp-m, null, 2));
        @include gh-responsive-gallery($config);
      }
    }
  }
}

a.feed-icon {
  text-align: center;
  display: block;
  margin-top: $dist;

  img {
    display: none;
  }

  &:before {
    @include fontawesome($fa-var-rss-square);
    font-size: 1.5em;
  }

  &:after {
    content: 'RSS-Feed abonnieren';
    margin-left: .5em;
    position: relative;
    top: -2px;
  }

  &:hover:before {
    color: #f26522
  }
}
