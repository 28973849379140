.paragraphs-item-text {
  @extend %formatted-text;

  &.grau-hinterlegt {
    background: $box-color;
    padding: $dist * 0.66;
  }

  .group-inner {
    display: block;
    width: 100%;
    max-width: 891px;
    margin-left: auto;
    margin-right: auto;
  }
}
