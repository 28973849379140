.view-mediathek {
  .view-filters {
    margin-bottom: $dist;

    label {
      display: none;
    }
  }

  .view-content {
    @extend %clearfix;

    .views-row {
      $config: (
          (null, $bp-m, 1),
          ($bp-m, null, 2)
      );

      @include gh-responsive-gallery($config);
      margin-top: $dist;

      @include breakpoint(null, $bp-m) {
        &:nth-child(1),
        &:nth-child(2) {
          margin-top: 0;
        }
      }
    }
  }
}
