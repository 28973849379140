.node-kompetenz-eintrag.view-mode-default {

  @include breakpoint(null, $bp-s) {
    .group-left {
      margin-bottom: $dist;
    }
  }

  @include breakpoint($bp-s, null) {
    .group-left {
      @include span(4);
    }

    .group-right {
      @include span(8 last);
      position: relative;
      z-index: 20;
    }
  }

  @include breakpoint($bp-m, null) {
    .group-left {
      position: relative;

      &:after {
        content: '';
        position: absolute;
        height: 100px;
        width: 100px;
        transform: rotate(45deg);
        background: rgba($highlight-color, .5);
        right: -50px;
        top: 40px;
        display: block;
      }
    }
  }

  .field-name-title {
    h2 {
      font-size: $fs-bigger;
      margin-top: -.25em;
      margin-bottom: $dist;

      &:after {
        content: '';
        width: 150px;
        height: 2px;
        background: $border-color;
        display: block;
        margin-top: 10px;
      }
    }
  }

  .field-name-field-bild {
    img {
      @include resp-img;
      box-shadow: 0 0 10px $border-color;
    }
  }

  .field-name-body {
    @extend %formatted-text;
  }

  .field-name-field-dateianhaenge {
    margin-top: $dist;
    padding: $dist;
    background-color: $box-color;

    span.file {
      margin-bottom: 10px;

      &:last-of-type {
        margin-bottom: 0;
      }

      img {
        display: none;
      }

      a {
        display: block;
        @include icon-before($fa-var-cloud-download);

        &:before {
          top: 5px;
        }
      }
    }
  }

  .field-name-field-bilder {
    @extend %clearfix;
    margin-top: $dist;

    a.colorbox {
      $conf: (
          (null, $bp-m, 2, 1, 8),
          ($bp-m, $bp-l, 3, 1, 8),
          ($bp-l, null, 4, 1, 8)
      );

      @include resp-gal($conf);
      @include img-hover();
      box-shadow: 0 0 10px $border-color;

      img {
        @include resp-img;
      }
    }
  }

  .field-name-field-link {
    margin-top: $dist;
    text-align: right;

    a {
      @extend %button;
    }
  }
}

.node-kompetenz-eintrag.view-mode-teaser {

  .field-name-custom-node-kompetenz-eintrag-im {
    a {
      @include gh-image-hover($fa-var-arrow-right, $highlight-color, left);

      &:after {
        text-shadow: 2px 1px rgba(0,0,0,.25);
      }

      &:hover {
        outline: solid 5px $highlight-color;

        img {
          opacity: 1;
        }
      }
    }

    img {
      @include resp-img();
      box-shadow: 0 0 10px $shadow-color;
    }
  }

  .field-name-custom-node-kompetenz-eintrag-ti {
    font-size: $fs-big;
    font-weight: $fw-bold;
    margin-top: $dist / 2;

    &:before {
      content: "";
      display: inline-block;
      width: 10px;
      height: 10px;
      background: $highlight-color;
      margin-right: 10px;
      position: relative;
      top: -2px;
    }
  }
}

#node-23391 {

  @include breakpoint($bp-s, null) {

    .group-left {
      width: 32.16666666666667%;
    }

    .group-right {
      width: calc(100% - (32.16666666666667% + 3%));
    }
  }

  .field-name-field-bild-link {
    font-size: 0;
  }
}
