.view-partner {
  .view-content {
    @extend %clearfix;

    .views-row {
      $config: (
          (null, $bp-s, 2),
          ($bp-s, $bp-m, 3),
          ($bp-m, null, 6)
      );

      @include resp-gal($config);

      .views-field-field-bild {
        img {
          @include resp-img;
        }
      }
    }
  }

  .more-link {
    margin-top: $dist;
  }
}
