%button {
  border: none;
  background: $highlight-color;
  color: #FFF;
  font-weight: $fw-regular;
  @include border-radius($border-radius);
  display: inline-block;
  padding: 8px 17px;
  text-decoration: none;
  @include transition (all, $duration);
  cursor: pointer;
  box-shadow: 0 0 5px $shadow-color;

  &:hover {
    text-decoration: none;
  }

  &:not(.disabled):hover {
    background: darken($highlight-color, 10%);
    color: #FFF;
  }

  &.disabled {
    cursor: default;
  }
}

%button-secondary {
  background: $button-secondary-color;
  color: $font-color;

  &:not(.disabled):hover {
    background: $button-secondary-color-hover;
  }
}


%button-round {
  border-radius: 50%;
  padding: 8px 15px;
}
