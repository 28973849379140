// @import "compass/reset";

// Files which are not created by grasenhiller
@import "vendor/all";

// Mixins and functions
@import "tools/all";

// Variables
@import "config/all";

// All the things you can extend
@import "extends/all";

// Styles that have to be applied in all templates
@import "basics";

// Grid System
@import "../../bower_components/susy/sass/susy";

// General Styles
html {
}

body {
  font-family: $ff-default;
  font-weight: $fw-regular;
  font-size: $fs-regular;
  color: $action-color;
  overflow-x: hidden;
}

h1, h2, h3, h4, h5 {
  font-family: $ff-headline;
  font-weight: $fw-bold;
  margin-bottom: $dist / 2;

}

h1 {
  font-size: $fs-big;
}

h2 {
  font-size: $fs-medium;
}

h3 {
  font-size: $fs-regular;
}

h4, h5 {
  font-size: $fs-regular;
}

h1.title {
  margin-bottom: $dist;
  border-bottom: solid 1px $border-color;
  padding-bottom: $dist;
  position: relative;

  span {
    padding: 15px;
    display: inline-block;
    background: $highlight-color;
    color: #FFF;
  }

  div.sub {
    color: $font-color-light;
    font-size: $fs-regular;
    font-weight: $fw-regular;
    margin-top: 1em;
  }
}

a {
  color: $action-color;
  text-decoration: none;

  &:hover {
    color: $action-color-hover;
    text-decoration: underline;
  }
}

a.button {
  @extend %button;

  &.secondary,
  &.button--secondary{
    @extend %button-secondary;
  }

  &.button--round {
    @extend %button-round;
  }
}

.tooltip {
  position: relative;

  .tooltip--content {
    @include gh-center(h);
    top: 100%;
    background: $button-secondary-color;
    padding: 5px 10px;
    border-radius: 5px;
    font-size: $fs-small;
    color: $font-color;
    white-space: nowrap;
    transition: all $duration;
    opacity: 0;
    visibility: hidden;

    &:before {
      display: block;
      content: '';
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 0 5px 5px 5px;
      border-color: transparent transparent $button-secondary-color transparent;
      @include gh-center(h);
      top: -5px;
      z-index: 479;
    }
  }

  &:hover {
    .tooltip--content {
      opacity: 1;
      visibility: visible;
      top: 120%;
    }
  }
}

.share-button-wrapper {
  display: inline-block;
  float: right;
  margin-top: 10px;

  @include breakpoint(null, $bp-m) {
    display: block;
    float: none;
  }

  .share-button.facebook {
    $fb-color: #3b5998;
    background: $fb-color;
    display: inline-block;
    color: #FFF;
    padding: .5em 1em;
    border-radius: 5px;
    font-size: $fs-regular;
    font-weight: $fw-regular;

    &:hover {
      background: darken($fb-color, 20%);
      text-decoration: none;
    }

    @include breakpoint(null, $bp-m) {
      padding: .3em .5em;
    }
  }
}


#block-system-main {
  @extend %clearfix;
}

// GH-Plugins
@import "gh-plugins/all";

// All the other stuff
@import "partials/all";

@import "merkzettel";
@import "merkzettel-fichtner";
