/**
 * @author B.Hofstaetter <bh@grasenhiller.de>
 */

div[class^="table-"] {
  margin-top: 1em;

  &:first-of-type {
    margin-top: 0;
  }

  & + p,
  & + ul,
  & + ol,
  & + h1,
  & + h2,
  & + h3,
  & + h4,
  & + h5,
  & + h6  {
    margin-top: 1em;
  }
}

table {
  // - Settings
  $padding: 7px;
  $font-weight-bold: $fw-bold;
  $border-color: $border-color;
  $even-row-color: $box-color;
  $odd-row-color: #ffffff;
  $table-head-color: darken($box-color, 10%);
  $font-size: $fs-regular;
  $font-size-small: $fs-small;
  $font-size-big: $fs-big;
  // ----------

  width: 100% !important;
  border-collapse: collapse !important;
  border-spacing: 0 !important;
  border: none !important;
  float: none !important;

  &.has-no-styling {
    &,
    tr,
    td,
    th {
      background: none !important;
      border: none !important;
      padding: 0 !important;
      margin: 0 !important;
      line-height: $line-height;
    }
  }

  &.has-two-columns {
    th,
    td {
      width: 50%;
    }
  }

  &.has-three-columns {
    th,
    td {
      width: 33.33333%;
    }
  }

  &.has-four-columns {
    th,
    td {
      width: 25%;
    }
  }

  tr {

    td,
    th {
      padding: $padding;
      border: none;
      border-bottom: 1px solid $border-color;
      border-right: 1px solid $border-color;

      &:first-of-type {
        border-left: 1px solid $border-color;
      }
    }

    th {
      font-weight: $font-weight-bold;
      // background: $border-color;
      background: $table-head-color;
      // border-bottom: 1px solid darken($border-color, 5);
      border-top: 1px solid darken($border-color, 5);
      color: $font-color;
      border-color: darken($table-head-color, 20%);
    }

    td {

    }
  }

  tbody {
    border: none;
    tr {
      &:last-of-type {
        th {
          border-bottom: 1px solid $border-color;
        }
      }
    }
  }

  &.has-no-thead,
  &.has-thead-left:not(.has-thead-top) {
    tr {
      &:nth-child(even) {
        background: $even-row-color;
      }

      &:nth-child(odd) {
        background: $odd-row-color;
      }

      &:first-of-type {
        border-top: 1px solid $border-color;
      }
    }
  }

  &.has-thead-top,
  &.has-two-theads {
    tbody tr {
      &:nth-child(even) {
        background: $even-row-color;
      }

      &:nth-child(odd) {
        background: $odd-row-color;
      }
    }

    thead {
      tr {
        border-top: 1px solid $border-color;
        background: $border-color;
      }
    }
  }

  &.has-two-theads,
  &.has-thead-left:not(.has-thead-top) {
    tbody tr {
      th {
        border-right: 1px solid darken($border-color, 5);
      }
    }
  }

  // - Vergleichstabelle
  &.comprehension-table {
    tr {
      &:first-of-type {
        border-top: none;

        td {
          background: $highlight-color !important;
          font-size: $font-size-big;
          border-right-color: darken($highlight-color, 5) !important;
          border-bottom-color: darken($highlight-color, 5) !important;

          &:first-of-type {
            background: #ffffff !important;
            border-left: none;
            border-right: none;
            border-bottom-color: $border-color !important;
            opacity: 0;
            visibility: hidden;
          }

          &:last-of-type {
            border-right-color: $highlight-color !important;
          }
        }
      }

      td {
        text-align: center;

        &:first-of-type {
          text-align: left;
          font-weight: $font-weight-bold;
        }

        &.comprehension-best-choice {
          border-right-color: #D2C386;
          border-bottom-color: #D2C386;
          background: rgba($highlight-color, 0.25);
        }

        &.comprehension-best-choice-border {
          border-right-color: #D2C386;
        }
      }
    }

    &.has-request-buttons {
      tr {
        &:last-of-type {
          td {
            &:first-of-type {
              background: #ffffff;
              border-left: none;

              opacity: 0;
              visibility: hidden;
            }
          }
        }
      }
    }

    &.first-empty {
      tr {
        td {
          &:first-of-type {
            display: none;
          }

          &:nth-of-type(2) {
            border-left: 1px solid $border-color;
          }
        }

        &:first-of-type {
          td {
            border-left-color: $highlight-color;
          }
        }
      }
    }
  }

  // - Responsive
  &.is-table-box.is-resp-table {
    tr {
      border-bottom: 1px solid $border-color;

      th,
      td {
        display: block;
        float: left;
        width: 100%;
      }

      th {
        background: $table-head-color;
        font-weight: $font-weight-bold;
        border-bottom: 1px solid $table-head-color;
        border-left: 1px solid $table-head-color;
        border-right: 1px solid $table-head-color !important;
        color: #ffffff;
      }

      td {
        border-left: 1px solid $border-color;
      }
    }

    // &.has-no-head,
    &.has-two-theads,
    &.has-thead-top {
      thead {
        display: none;
      }

      tr {
        td {
          font-size: $font-size-small;
          text-align: right;

          &:before {
            content: attr(data-th);
            float: left;
          }
        }
      }
    }

    // &.has-no-head,
    &.has-thead-top:not(.has-thead-left) {
      tr {
        td {
          &:first-of-type {
            font-weight: $font-weight-bold;
            font-size: $font-size;
            text-align: left;
            background: $table-head-color;
            color: #ffffff;
            border-bottom: 1px solid $table-head-color;
            border-left: 1px solid $table-head-color;
            border-right: 1px solid $table-head-color;

            &:before {
              display: none;
            }
          }
        }
      }
    }
  }
}

.no-columns {
  -webkit-column-count: 1 !important;
  -moz-column-count: 1 !important;
  column-count: 1 !important;
}

.paragraph-sektion.grau {
   .comprehension-table {
    tr {
      &:first-of-type {
        td:first-of-type {
          border-right-color: $border-color;
          background: $box-color !important;
        }
      }
    }

    &.has-request-buttons {
      tr {
        &:last-of-type {
          td:first-of-type {
            border-right-color: $border-color;
            background: $box-color !important;
          }
        }
      }
    }
  }
}

.table-scroll-wrapper {
  overflow-y: auto;
  position: relative;

  // &:after {
  //   content: '';
  //   display: block;
  //   position: fixed;
  //   top: 0;
  //   right: 0;
  //   bottom: 0;
  //   width: 2em;
  //   background: rgba(#000000, 0.2);
  // }

  table.is-resp-table {
    font-size: 0.75em !important;
  }
}

body.page-node-369 {
  .paragraph-sektion.onecol .paragraphs-item-text  {
    -webkit-column-count: 1;
  }
}
