$states: (
  ("s", null,  $bp-s),
  ("m", $bp-s, $bp-m),
  ("l", $bp-m, null)
);

$sizes: (3,4,6,8,9,12);

@each $state in $states {
  @include breakpoint(nth($state, 2), nth($state, 3)) {
    @each $size in $sizes {
      .col-#{nth($state, 1)}-#{$size} {
        @include span($size);
      }
    }

    .omega-#{nth($state, 1)} {
      @include last;
    }
    .alpha-#{nth($state, 1)} {
      @include break;
    }
  }
}
