.paragraphs-item-formular {
  &[max-width~='#{span-breite(4)}'] {
    .webform-component {
      label {
        width: auto;
        display: block;
        float: none;
        padding: 6px 0;
      }

      .form-text,
      .form-select,
      .form-textarea-wrapper,
      .form-textarea-wrapper textarea {
        width: 100% !important;
      }
    }
  }

  .webform-component--datenschutz {


    .form-checkboxes {
      margin-top: 5px;

      @include breakpoint(null, 530px) {
        width: 100% !important;
      }

      input {
        float: left;
        margin-right: $dist/2;
        margin-top: 5px;
      }

      label {
        width: calc(100% - 50px) !important;
        float: left;
      }
    }
  }
}
