////
/// @author Max Bachhuber
/// @group grasenhiller
////

/// Apply SCSS to this element only in a specific media query
/// The from and to params could also be null, then the breakpoint will just be a max- or min- query
///
/// @param {Pixel} $from [null] - Min width where the breakpoint should start
/// @param {Pixel} $to [null] - Max width where the breakpoint should end
/// @param {Boolean} $incrementFrom [true] - If the from value should be incremented by one. Useful for breakpoint variables
/// @content The SCSS which should be applied in this specific breakpoint
///
/// @example scss - Basic Usage
///   // This example would result in @media screen and (min-width: 200px) (max-width: 500px)
///   .my-element {
///     background-color: red;
///
///     @include breakpoint(200px, 500px, false) {
///       background-color: blue;
///     }
///   }
///
/// @example scss - Only max-width
///   // This example would result in @media screen and (max-width: 500px)
///   .my-element {
///     background-color: red;
///
///     @include breakpoint (null, 500px, false) {
///       background-color: blue;
///     }
///   }
///
/// @example scss - Only min-width
///   // This example would result in @media screen and (min-width: 500px)
///   .my-element {
///     background-color: red;
///
///     @include breakpoint (500px, null, false) {
///       background-color: blue;
///     }
///   }
///
/// @example scss - Using breakpoint variables
///   // This example would result in @media screen and (min-width: 451px) (max-width: 800px)
///   $breakpoint-mobile: 450px;
///   $breakpoint-tablet: 800px;
///
///   .my-element {
///     background-color: red;
///
///     @include breakpoint ($breakpoint-mobile, $breakpoint-tablet) {
///       background-color: blue;
///     }
///   }

@mixin breakpoint($from: null, $to: null, $incrementFrom: true) {
  @if $from != null and $to != null {
    @if $incrementFrom {
      $from: $from + 1px;
    }

    @media screen and (min-width: $from) and (max-width: $to) {
      @content;
    }
  }

  @if $from !=null and $to == null {
    @if $incrementFrom {
      $from: $from + 1px;
    }

    @media screen and (min-width: $from) {
      @content;
    }
  }

  @if $from == null and $to != null {
    @media screen and (max-width: $to) {
      @content;
    }
  }
}
