////
/// @author Max Bachhuber
/// @group grasenhiller
////

/// Centers a div vertically in a "position relative" or "position absolute" container
///
/// @require {mixin} transform
///
/// @example scss - Basic usage
///   .outer {
///     position: relative;
///
///     .inner {
///       @include gh-vertical-center();
///     }
///   }

@mixin gh-vertical-center() {
  position: absolute;
  top: 50%;
  @include transform(translateY(-50%))
}

/// @alias gh-vertical-center
/// @require {mixin} gh-vertical-center

@mixin vcenter() {
  @include gh-vertical-center();
}
