.node-produkt {
  &.view-mode-full {
    .field-name-field-produkt-bilder {
      margin-top: $dist;
      @extend %clearfix;

      a.colorbox {
        $config: (
            (null, $bp-s, 1),
            ($bp-s, $bp-m, 2),
            ($bp-m, $bp-l, 3),
            ($bp-l, null, 4)
        );

        display: block;
        @include gh-responsive-gallery($config);
        @include gh-image-hover();
      }
    }

    .field-name-field-ean-nummer {
      margin-top: .5em;
    }

    .field-name-body {
      margin-top: 1em;
    }

    .field-name-field-dateien {
      margin-bottom: .5em;

      &:not(:first-child) {
        margin-top: .5em;
      }
    }

    .field-name-field-sku + .field-name-field-m-sku {
      &:before {
        top: 0 !important;
      }
    }

    .field-name-custom-code-produkt-buttons {
      margin-top: 1em;

      .go-to-product {
        display: none;
      }
    }
  }

  &.view-mode-full,
  &.view-mode-teaser_2 {

    .field-name-field-sku {
      @include icon-before($fa-var-info);
      display: inline-block;

      &:before {
        color: $font-color-light;
      }
    }

    .field-name-field-m-sku {
      @include icon-before($fa-var-info);

      &:before {
        color: $font-color-light;
      }
    }

    .field-name-field-ean-nummer {
      @include icon-before($fa-var-barcode);

      &:before {
        color: $font-color-light;
      }
    }

    // Slash zwischen Artikelnummer und Herstellernummer
    .field-name-field-sku + .field-name-field-m-sku {
      display: inline-block;
      padding-left: 0;

      &:before {
        content: " / ";
        width: auto;
        margin: 0 5px;
        top: 4px;
      }
    }


    .field-name-custom-code-produkt-buttons {
      margin-top: 1em;

      a:first-of-type {
        margin-right: .5em;
      }

      a.tooltip {
        z-index: 479;
      }

      a.add-to-merkzettel-confirm {
        padding: 8px 13px;
        margin-top: .5em;
        margin-bottom: .5em;
      }

      .merkzettel--add-region {
        display: none;
        margin-top: .5em;

        input[type="radio"] {
          display: none;

          & + label {
            position: relative;

            &:before {
              content: "";
              width: 1.5em;
              height: 1.5em;
              background: #FFF;
              border: solid 1px #c7cfd7;
              display: inline-block;
              position: relative;
              top: 6px;
              border-radius: 50%;
              margin-right: .5em;
              font-size: 1rem;
            }
          }

          &:checked + label {
            &:after {
              content: "";
              width: .5em;
              height: .5em;
              background: $font-color;
              position: absolute;
              left: 8px;
              top: 7px;
              border-radius: 50%;
              font-size: 1rem;
            }
          }
        }

        .merkzettel--menge-wrapper {
          margin-top: .5em;
        }
      }

      .merkzettel--menge-wrapper {
        display: none;
      }
    }

    .field-name-field-dateien {
      img {
        display: none;
      }

      a {
        display: block;
        @include icon-before($fa-var-file-pdf-o);

        &:before {
          color: $font-color-light;
        }
      }
    }

    .field-name-field-produkt-bilder {
      position: relative;

      img {
        @include resp-img;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);
      }
    }
  }

  &.node-teaser {
    @extend %clearfix;
    .group-left {
      position: relative;
      @include span(3 of 12);

      &:after {
        content: '';
        position: absolute;
        height: 50px;
        width: 50px;
        transform: rotate(45deg);
        background: rgba($highlight-color, .5);
        right: -25px;
        top: 25px;
        display: block;

        @include breakpoint(null, $bp-m) {
          height: 25px;
          width: 25px;
          right: -12px;
          top: 12px;
        }
      }

      a {
        @include img-hover();

        img {
          @include resp-img;
          box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);
        }
      }
    }

    .group-right {
      @include span(9 of 12 last);

      .field-name-title {
        h3 {
          @extend %sidebar-heading;
        }
      }

      .field-name-field-sku {
        &:before {
          @include fontawesome($fa-var-tag);
          margin-right: 0.5em;
          color: $font-color-light;
        }
      }

      .field-name-field-sku,
      .field-name-field-m-sku {
        display: inline-block;
        line-height: $line-height;
      }

      .field-name-field-sku {
        & + .field-name-field-m-sku {
          &:before {
            content: '\00a0/\00a0';
          }
        }
      }

      .field-name-field-m-name {
        line-height: $line-height;

        &:before {
          @include fontawesome($fa-var-info);
          margin-right: 0.5em;
          color: $font-color-light;
        }
      }

      .field-name-field-dateien {
        line-height: $line-height;

        &:before {
          @include fontawesome($fa-var-file-pdf-o);
          margin-right: 0.5em;
          color: $font-color-light;
        }

        .file {
          img {
            display: none;
          }

          & + .file {
            &:before {
              content: ', ';
            }
          }
        }
      }

      .field-name-body {
        position: relative;
        margin-top: $dist/2;
        max-height: $line-height-int * 4 + em;
        overflow: hidden;

        &.more:not(.show-more) {
          &:after {
            content: '';
            display: block;
            position: absolute;
            z-index: 10;
            bottom: 0;
            left: 0;
            right: 0;
            height: $line-height-int * 1.5 + em;
            /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#ffffff+0,ffffff+70,ffffff+100&0+0,1+80,1+100 */
            background: -moz-linear-gradient(top, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.75) 70%, rgba(255, 255, 255, 1) 80%, rgba(255, 255, 255, 1) 100%); /* FF3.6-15 */
            background: -webkit-linear-gradient(top, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.75) 70%, rgba(255, 255, 255, 1) 80%, rgba(255, 255, 255, 1) 100%); /* Chrome10-25,Safari5.1-6 */
            background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.75) 70%, rgba(255, 255, 255, 1) 80%, rgba(255, 255, 255, 1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
            filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#00ffffff', endColorstr='#ffffff', GradientType=0); /* IE6-9 */
          }
        }

        &.show-more {
          overflow: visible;
          max-height: 100%;
        }
      }
    }

    @include breakpoint(null, $bp-s) {
      .group-left,
      .group-right {
        @include span(full);
      }

      .group-right {
        margin-top: $dist/2;
      }

      .group-left {
        &:after {
          display: none;
        }
      }
    }
  }

  &.view-mode-teaser_2 {
    @extend %clearfix;

    .group-left {
      @include span(1 of 4);
    }

    .group-right {
      @include span(3 of 4 last);
    }

    .field-name-title {
      h2 {
        @extend %sidebar-heading;
      }
    }

    .field-name-field-produkt-bilder {
      position: relative;

      &:after {
        content: '';
        position: absolute;
        height: 10px;
        width: 10px;
        transform: rotate(45deg);
        background: rgba($highlight-color, .5);
        right: -5px;
        top: 10px;
        display: block;
      }
    }
  }
}

.more-button-product {
  margin-top: $dist/2;
  @extend %button;
  @extend %button-secondary;
}

.paragraphs-item-entity-reference {
  & > .node {
    margin-top: $dist;

    &:first-of-type {
      margin-top: 0;
    }
  }
}
