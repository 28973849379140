////
/// @author Max Bachhuber
/// @group grasenhiller
////

/// Adds a hover effect with an icon to an image.
///
/// Should be applied to a wrapper (a / div / span) of an img tag.
///
/// @param {String} $icon [$fa-search] - The unicode id of a fontawesome icon. You could use the `$fa` variables.
/// @param {Color} $color [$highlight-color] - The color of the overlay.
/// @param {top | bottom | left | right | none} $origin [top] - The origin, where the icon should come from.
/// @require {mixin} transition
/// @require {mixin} fontawesome
/// @require {mixin} transform
/// @require {mixin} opacity
///
/// @example scss - Basic usage
///   .views-field-image {
///     a {
///       @include gh-image-hover($fa-eye, red);
///     }
///   }

@mixin gh-image-hover($icon: $fa-var-search-plus, $color: $highlight-color, $origin: top) {
  @include transition(all, .4s);
  background: $color;
  display: block;
  position: relative;
  text-decoration: none;

  img {
    @include transition(all, .4s);
  }

  &:after {
    @include fontawesome($icon);
    position: absolute;
    top: 50%;
    left: 50%;
    color: #FFF;
    z-index: 499;
    font-size: 30px;
    @include opacity(0);
    @include transition(all, .4s);
    text-shadow: 2px 1px rgba(0,0,0,.25);

    @if $origin == top {
      @include transform(translate(-50%, -100%));
    } @else if $origin == bottom {
      @include transform(translate(-50%, 100%));
    } @else if $origin == left {
      @include transform(translate(-100%, -50%));
    } @else if $origin == right {
      @include transform(translate(100%, -50%));
    } @else {
      @include transform(translate(-50%, -50%));
    }
  }

  &:hover {
    &:after {
      @include opacity(1);
      @include transform(translate(-50%, -50%));
    }

    outline: solid 5px $highlight-color;

    img {
      //@include opacity(.15);
      box-shadow: 0 0 10px $shadow-color;
    }
  }
}


/// @alias gh-image-hover

@mixin img-hover($args...) {
  @include gh-image-hover($args...);
}
