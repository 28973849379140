body.front {
  h1#page-title {
    display: none;
  }

  .region-content {
    @extend %clearfix;

    & > .block {
      margin-bottom: $dist * 2;

      &:nth-last-of-type(2) {
        margin-bottom: 0;
      }

      &:not(#block-mailchimp-signup-newsletter-anmeldung) > h2 {
        @include icon-before($fa-var-building-o);
        padding-bottom: .5em;
        margin-bottom: $dist;
        border-bottom: solid 2px $highlight-color;

        &:before {
          top: 4px;
        }
      }
    }

    #block-bean-custom-adresse-und-kontakt-start {
      .content {
        a {
          display: inline-block;
          @include icon-before($fa-var-phone);
          &:before {
            color: $font-color-light;
          }

          &:nth-of-type(2):before {
            content: $fa-var-print;
          }

          &:nth-of-type(3):before {
            content: $fa-var-paper-plane-o;
          }
        }
      }
    }

    #block-bean-custom-oeffnungszeiten-startseit {
      & > h2:before {
        content: $fa-var-clock-o;
      }

      a.button {
        font-weight: $fw-regular;
        margin-top: 1em;
      }

      table {
        border: none;
        background: none;
        width: auto !important;

        tr {
          background: none;
          border: none;

          td {
            background: none;
            border: none;
            padding: 0;

            &:nth-child(2) {
              padding-left: 1em;
            }
          }
        }
      }
    }

    #block-views-kompetenzen-block-1 {
      & > h2:before {
        content: $fa-var-connectdevelop;
      }

      & > h2 {
        @include breakpoint($bp-m, null) {
          display: none;
        }
      }

      @include breakpoint($bp-m, null) {
        position: relative;

        & > .content {
          width: 100vw;
          position: absolute;
          left: 50%;
          transform: translateX(-50%);
        }
      }
    }

    #block-views-blog-block-1 {
      & > h2:before {
        content: $fa-var-bullhorn;
      }
    }

    #block-views-termine-workshops-block-1 {
      & > h2:before {
        content: $fa-var-calendar-o;
      }
    }

    #block-views-angebote-block-1 {
      h1 {
        text-align: center;
        margin-bottom: 0;
        padding-top: $dist;
        padding-bottom: $dist;
        box-shadow: 0 0 10px $shadow-color;
      }


      position: relative;

      & > .content {
        width: 100vw;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
      }
    }

    #block-views-mediathek-block-1 {
      & > h2:before {
        content: $fa-var-play;
      }
    }

    #block-views-partner-block {
      & > h2:before {
        content: $fa-var-group;
      }
    }

    #block-system-main {
      display: none;
    }

    // Layout
    @include breakpoint(null, $bp-s) {
      #block-bean-custom-adresse-und-kontakt-start {
        @include span(12 last);
      }
      #block-bean-custom-oeffnungszeiten-startseit {
        @include span(12 last);
      }
      #block-views-kompetenzen-block-1 {
        @include span(12 last);
      }
      #block-views-blog-block-1 {
        @include span(12 last);
      }
      #block-views-termine-workshops-block-1 {
        @include span(12 last);
      }
      #block-views-angebote-block-1 {
        @include span(12 last);
      }
      #block-views-mediathek-block-1 {
        @include span(12 last);
      }
      #block-views-partner-block {
        @include span(12 last);
      }
    }

    @include breakpoint($bp-s, $bp-m) {
      #block-bean-custom-adresse-und-kontakt-start {
        @include span(6);
      }
      #block-bean-custom-oeffnungszeiten-startseit {
        @include span(6 last);
      }
      #block-views-kompetenzen-block-1 {
        @include span(12 last);
      }
      #block-views-blog-block-1 {
        @include span(12 last);
      }
      #block-views-termine-workshops-block-1 {
        @include span(12 last);
      }
      #block-views-angebote-block-1 {
        @include span(12 last);
      }
      #block-views-mediathek-block-1 {
        @include span(12 last);
      }
      #block-views-partner-block {
        @include span(12 last);
      }
    }

    @include breakpoint($bp-m, null) {
      #block-bean-custom-adresse-und-kontakt-start {
        @include span(6);
      }
      #block-bean-custom-oeffnungszeiten-startseit {
        @include span(6 last);
      }
      #block-views-kompetenzen-block-1 {
        @include span(12 last);
      }
      #block-views-blog-block-1 {
        @include span(6);
      }
      #block-views-termine-workshops-block-1 {
        @include span(6 last);
      }
      #block-views-angebote-block-1 {
        @include span(12 last);
      }
      #block-views-mediathek-block-1 {
        @include span(12 last);
      }
      #block-views-partner-block {
        @include span(12 last);
      }
    }
  }
}
