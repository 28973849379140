// Basic Form Component Styling
input[type="submit"],
button {
  @extend %button;
}

label[for] {
  cursor: pointer;
}

input[type="text"],
input[type="email"],
input[type="password"],
input[type="number"],
select,
textarea {
  // Border, Border-Radius, Paddings, ...
  padding: 7px 10px;
  border: solid 1px $border-color;
  border-radius: $border-radius;
  transition: border-color $duration, outline $duration;

  &:focus {
    border-color: $highlight-color;
    outline: none;
  }

  @mixin placeholder {
    line-height: 1.4em;
    color: #989898;
  }

  &::-webkit-input-placeholder {
    @include placeholder;
  }

  &:-moz-placeholder {
    @include placeholder;
  }

  &::-moz-placeholder {
    @include placeholder;
  }

  &:-ms-input-placeholder {
    @include placeholder;
  }

  &:placeholder-shown {
    @include placeholder;
  }
}

select.placeholder {
  color: #989898;

  option {
    color: $font-color;
  }
}


// Webform Styling
$bp-webform: 530px;

.webform-client-form {
  .webform-component {
    $label-width: 130px;
    margin-bottom: .5em;

    @extend %clearfix;

    label {
      display: block;
      padding: 6px 0;

      @include breakpoint($bp-webform, null) {
        width: $label-width;
        display: inline-block;
        float: left;
      }
    }

    .form-text,
    .form-select,
    .form-textare-wrapper,
    .angebotsprodukte-select{
      width: 100%;
    }

    label:not(.element-invisible) {
      & + .form-text,
      & + .form-select,
      & + .form-textarea-wrapper,
      & + .angebotsprodukte-select {
        @include breakpoint($bp-webform, null) {
          width: calc(100% - #{$label-width});
          float: left;
        }
      }
    }
  }

  .captcha {
    @include breakpoint($bp-webform, null) {
      margin-left: 130px;
    }
  }
  .form-actions {
    text-align: left;
    margin-top: .5em;

    @include breakpoint($bp-webform, null) {
      padding-left: 130px;
    }
  }
}

// Grippie muss sterben...
.resizable-textarea .grippie {
  display: none
}

textarea {
  resize: vertical;
  min-height: 250px;
  line-height: $line-height;
}

.webform-component-checkboxes {
  .description,
  .form-checkboxes {
    float: left;
    width: calc(100% - 130px);


    .form-item.form-type-checkbox {

    }
  }
}
