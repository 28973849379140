.view-kompetenzen.view-display-id-page {
  @extend .view-referenzen;
}

.view-kompetenzen.view-display-id-block_1 {
  .view-content {
    @extend %clearfix;
    font-size: 0;
    text-align: center;

    @include breakpoint(null, $bp-m) {
      white-space: nowrap;
      overflow-x: scroll;
      overflow-y: hidden;
    }

    .views-row {
      display: inline-block;
      vertical-align: top;
      position: relative;
      font-size: $fs-regular;

      @include breakpoint(null, $bp-m) {
        width: 200px;
      }

      @include breakpoint($bp-m, null) {
        width: 20%;
      }

      .views-field-field-bild {
        img {
          @include resp-img;
        }
      }

      .blue-wrapper {
        padding: $dist / 2;
        text-align: center;
        color: #FFF;
        transition: all .4s;
        position: absolute;
        bottom: -1px;
        left: 0;
        right: 0;
        top: auto;
        background: rgba($highlight-color, .7);

        a {
          color: #FFF;
        }
      }

      .views-field-title {
        font-size: $fs-big;
        font-weight: $fw-bold;
        white-space: normal;

        @include breakpoint($bp-m, $bp-l) {
          font-size: $fs-regular;
        }
      }

      .views-field-nid,
      .views-field-body {
        display: none;
      }

      .views-field-body {
        margin-bottom: .5em;
      }

      .views-field-nid {
        font-size: $fs-big;
      }

      @include breakpoint($bp-m, null) {
        &:hover {
          .blue-wrapper {
            top: 0;
          }

          .views-field-title {
            padding-bottom: .5em;
            border-bottom: solid 2px #FFF;
            margin-bottom: 1em;

          }

          .views-field-nid,
          .views-field-body {
            display: block;
          }
        }
      }
    }
  }

  .view-footer {
    text-align: center;
    margin-top: $dist / 2;

    @include breakpoint($bp-m, null) {
      display: none;
    }
  }
}