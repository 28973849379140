// Markup generated by theme_menu_local_tasks().
ul.primary {
  border-bottom: 1px solid #bbb;
  border-collapse: collapse;
  height: auto;
  line-height: normal;
  list-style: none;
  margin: 5px;
  padding: 0 0 0 1em; /* LTR */
  white-space: nowrap;
}
ul.primary li {
  display: inline;
}
ul.primary li a {
  background-color: #ddd;
  border-color: #bbb;
  border-style: solid solid none solid;
  border-width: 1px;
  height: auto;
  margin-right: 0.5em; /* LTR */
  padding: 0 1em;
  text-decoration: none;
}
ul.primary li.active a {
  background-color: #fff;
  border: 1px solid #bbb;
  border-bottom: 1px solid #fff;
}
ul.primary li a:hover {
  background-color: #eee;
  border-color: #ccc;
  border-bottom-color: #eee;
}
ul.secondary {
  border-bottom: 1px solid #bbb;
  padding: 0.5em 1em;
  margin: 5px;
}
ul.secondary li {
  border-right: 1px solid #ccc; /* LTR */
  display: inline;
  padding: 0 1em;
}
ul.secondary a {
  padding: 0;
  text-decoration: none;
}
ul.secondary a.active {
  border-bottom: 4px solid #999;
}
