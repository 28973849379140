.page-menu {
  position: relative;
  padding: $dist 0;
  @include box-shadow(0,0,10px,0,$shadow-color);

  .container {
    @extend %clearfix;
    position: relative;
  }

  a.logo {
    float: left;
    max-width: 200px;
    max-height: 2 * $dist;
    width: 100%;
    height: auto;

    img {
      width: 260px;

      @include breakpoint(null, $bp-m + 100px) {
        width: 210px;
      }
    }
  }

  a.menu-btn {
    display: none;
    position: relative;
    @include vcenter();
    right: 0;
    cursor: pointer;
    color: $highlight-color;
    font-size: $fs-bigger;

    @include breakpoint(null, 650px) {
      display: block;
    }
  }

  nav.main-nav {
    position: relative;
    @include vcenter();
    right: 0;
    display: none;
    z-index: 499;

    @include breakpoint(650px, null) {
      display: block;
    }

    & > ul.menu {
      @extend %clearfix;
      display: inline-block;

      & > li {
        float: left;
        margin-right: $dist;
        position: relative;

        @include breakpoint(null, $bp-m) {
          margin-right: $dist / 2;
        }

        &.last {
          margin-right: 0;
        }

        & > a,
        & > span {
          display: block;
          margin: 1em 0;
          text-decoration: none;
          font-size: gh-rem(20px);
          color: $font-color;
          border-bottom: solid 2px #FFF;

          @include breakpoint(null, $bp-m) {
            font-size: gh-rem(18px);
          }

          &:hover,
          &.active,
          &.active-trail {
            border-bottom: solid 2px $highlight-color;
          }
        }

        &:hover {
          & > ul.menu {
            opacity: 1;
            visibility: visible;
            top: calc(100% - 2px);
          }
        }

        & > ul.menu {
          position: absolute;
          top: calc(100% - 1em);
          opacity: 0;
          visibility: hidden;
          background: $action-color;
          @include transition(all, $duration);
          @include gh-center('h');
          z-index: 499;
          padding: $dist / 2 $dist;
          text-align: center;

          @include breakpoint(null, $max-width + 100px) {
            //@include nocenter();
            //top: 1em;
            //right: 0;
          }

          & > li {
            margin-bottom: $dist / 2;

            &:last-child {
              margin-bottom: 0;
            }

            & > a,
            & > span {
              display: block;
              padding: 0;
              white-space: nowrap;
              color: #ffffff;
              //border-bottom: 1px solid $pushy-bg-color;
            }

            &.expanded {
              & > a,
              & > span {
                padding-bottom: 0;
                border-bottom: 0;
              }
            }

            & > ul.menu {
              border-bottom: 1px solid $pushy-bg-color;

              & > li {
                & > a,
                & > span {
                  display: block;
                  padding: $dist/4 $dist;
                  white-space: nowrap;
                  color: #ffffff;
                  font-size: 0.85em;
                }

                &:last-of-type {
                  & > a,
                  & > span {
                    padding-bottom: $dist/2;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
