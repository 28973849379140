.page-banner {
  .bean-startseiten-banner,
  .bean-banner {
    & > .content {
      position: relative;
      overflow: hidden;

      @include breakpoint(null, $bp-s) {
        height: 150px;

        .field-name-field-bild {
          width: 576px;

          .field-items .field-item {
            height: 150px;
          }
        }
      }

      @include breakpoint($bp-s, $bp-m) {
        height: 250px;

        .field-name-field-bild {
          width: 960px;

          .field-items .field-item {
            height: 250px;
          }
        }
      }

      @include breakpoint($bp-m, $bp-l) {
        height: 400px;

        .field-name-field-bild {
          width: 1536px;

          .field-items .field-item {
            height: 400px;
          }
        }
      }

      @include breakpoint($bp-l, null) {
        height: 500px;

        .field-name-field-bild {
          width: 1920px;

          .field-items .field-item {
            height: 500px;
          }
        }
      }

      .field-name-field-bild {
        position: absolute;
        @include gh-center('b');
        z-index: -10;
        height: 100%;

        img {
          height: 100%;
          width: auto;
        }
      }

      .field-name-field-fakten {
        @extend .container;
        position: absolute;
        bottom: $dist;
        @include gh-center('h');

        @include breakpoint(null, $bp-m) {
          display: none;
        }

        & > .field-items {
          @extend %clearfix;

          & > .field-item {
            @include gh-gallery(4);
            background-color: $action-color;
            text-align: center;
            padding: $dist;
            color: #FFF;

            .field-name-field-text-oben {
              font-size: $fs-bigger * 1.2;
              font-weight: $fw-bold;

              @include breakpoint()
            }

            .field-name-field-text-unten {
              font-size: $fs-big * 1.2;
              margin-top: .5em;
            }
          }
        }
      }
    }
  }
}
