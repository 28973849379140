.view-produkt-bersicht {
  .view-content {
    @extend %clearfix;

    .views-row {
      $config: (
          (null, $bp-l + 100px, 1),
          ($bp-l + 100px, null, 2)
      );

      @include resp-gal($config);
    }
  }
}
